<template>
  <div class="mt-4 overflow-x-scroll pb-8">
    <AssetsTable :assets="assets" show-eacs show-kind @row-clicked="handleRowClicked" />
    <PaginationButtons class="shadow-top p-2" :page-info="pageInfo" @load-items="loadAssets" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import type { AssetWithSummary } from "@/modules/asset/asset.service"
import AssetsTable from "@/modules/asset/components/AssetsTable.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { useAssetService } from "@/services/service-container"

const props = defineProps<{ portfolioId?: string }>()

const assetService = useAssetService()
const router = useRouter()

const assets = ref<AssetWithSummary[]>([])
const pageInfo = ref<PageInfo>()

const loadAssets = async (url?: string) => {
  try {
    const filters = {
      portfolioId: props.portfolioId,
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
    }
    const result = await assetService.listAssetsAdmin({ url, ...filters })
    assets.value = result.data
    pageInfo.value = result.pageInfo
  } catch (error) {
    console.error("There was an error loading assets", error)
  }
}

onMounted(() => {
  loadAssets()
})

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/admin/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}
</script>
