import { computed, ref } from "vue"
import { defineStore } from "pinia"
import type {
  AssetTimeSeriesMissingData,
  AssetTimeSeriesDesiredFrequency,
  AssetTimeSeriesTimeKind,
  AssetTimeSeriesValueUnit,
} from "@/models/assetTimeSeries"

export type TimeSeriesEntry = { hour: string; value: number; metadata: any }

export type AssetTimeSeriesUploadInput = {
  assetId: string | number
  fileData: File
  missing_data: AssetTimeSeriesMissingData
  desired_frequency: AssetTimeSeriesDesiredFrequency
  time_column: string
  time_kind: AssetTimeSeriesTimeKind
  time_format: string
  timezone: string
  value_column: string
  value_units: AssetTimeSeriesValueUnit
  value_null: string
  delimiter: string
  skip_lines: number
}

export type AssetTimeSeriesUploadError = {
  loc: string[]
  msg: string
  type: string
}

export const useAssetTimeSeriesStore = defineStore("assetTimeSeries", () => {
  const assetTimeSeriesFile = ref<File>()
  const assetTimeSeriesErrors = ref<string | AssetTimeSeriesUploadError[]>()
  const assetTimeSeriesInputs = ref<AssetTimeSeriesUploadInput>()
  const entries = ref<TimeSeriesEntry[]>([])

  const numErrors = computed<number>(() => {
    if (assetTimeSeriesErrors.value === undefined) {
      return 0
    }
    if (typeof assetTimeSeriesErrors.value === "string") {
      return 1
    }
    return assetTimeSeriesErrors.value?.length || 0
  })

  const assetTimeSeriesGlobalError = computed<string | null>(() => {
    if (numErrors.value === 1 && typeof assetTimeSeriesErrors.value === "string") {
      return assetTimeSeriesErrors.value as string
    }
    return null
  })

  const assetTimeSeriesRowErrors = computed<AssetTimeSeriesUploadError[]>(() => {
    if (numErrors.value > 0 && typeof assetTimeSeriesErrors.value !== "string") {
      return assetTimeSeriesErrors.value as AssetTimeSeriesUploadError[]
    }
    return []
  })

  const isSubmissionValid = computed<boolean>(() => numErrors.value === 0 && entries.value.length > 0)

  function $reset() {
    assetTimeSeriesFile.value = undefined
    assetTimeSeriesErrors.value = []
    assetTimeSeriesInputs.value = undefined
    entries.value = []
  }

  return {
    // refs
    assetTimeSeriesFile,
    assetTimeSeriesErrors,
    assetTimeSeriesGlobalError,
    assetTimeSeriesRowErrors,
    assetTimeSeriesInputs,
    entries,

    // computed
    isSubmissionValid,
    numErrors,

    // methods
    $reset,
  }
})
