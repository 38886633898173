<template>
  <div class="inline-flex shrink-0">
    <component
      :is="componentIs"
      :autofocus="autofocus"
      :class="[
        'wc-button flex grow items-baseline justify-center gap-2 rounded transition-all ease-in-out',
        `wc-button--${variant}`,
        `wc-button--${color}`,
        `wc-button--${size}`,
        {
          'wc-button--dark-mode': darkMode,
          'wc-button--disabled': isDisabled,
        },
        buttonClasses,
      ]"
      :disabled="isDisabled"
      v-bind="componentSpecificProps">
      <WcButtonInternalIcon v-if="icon && iconPosition === 'left'" :icon="icon" :color="color" :variant="variant" />
      {{ text }}
      <WcButtonInternalIcon v-if="icon && iconPosition === 'right'" :icon="icon" :color="color" :variant="variant" />
    </component>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import type { ButtonColor, ButtonColorLightMode, ButtonIconPosition, ButtonSize, ButtonType, ButtonVariant } from "./WcButton"
import WcButtonInternalIcon from "./WcButtonInternalIcon.vue"

/* Disallow accent-yellow color for light mode */
type DarkModeByColor = { darkMode?: false | undefined; color?: ButtonColorLightMode } | { darkMode: true; color?: ButtonColor }

type BaseProps = {
  autofocus?: boolean
  buttonClasses?: string
  color?: ButtonColor
  icon?: string
  iconPosition?: ButtonIconPosition
  isDisabled?: boolean
  size?: ButtonSize
  text?: string
  variant?: ButtonVariant
} & DarkModeByColor

type ButtonProps = BaseProps & {
  href?: never
  openInNewTab?: never
  to?: never
  type?: ButtonType
}

type AnchorProps = BaseProps & {
  href: string
  openInNewTab?: boolean
  to?: never
  type?: never
}

type RouterLinkProps = BaseProps & {
  href?: never
  openInNewTab?: boolean
  to: string | object
  type?: never
}

export type Props = ButtonProps | AnchorProps | RouterLinkProps

const props = withDefaults(defineProps<Props>(), {
  color: "green",
  darkMode: false,
  iconPosition: "left",
  isDisabled: false,
  size: "large",
  type: "button",
  variant: "primary",
})

const componentIs = computed(() => {
  if (props.href !== undefined || props.type === "anchor") {
    return "a"
  } else if (props.to) {
    return "router-link"
  } else {
    return "button"
  }
})
const componentSpecificProps = computed(() => {
  if (props.href || props.type === "anchor") {
    return {
      href: props.href,
      target: props.openInNewTab ? "_blank" : undefined,
    }
  }

  if (props.to) {
    return { to: props.to, target: props.openInNewTab ? "_blank" : undefined }
  }

  return { type: props.type }
})
</script>

<style scoped lang="scss">
// Import global styles so this can reference mixins
@use "@/static/styles/index.scss" as global;

.wc-button {
  &:not(.wc-button--disabled) {
    cursor: pointer;
    &:active {
      transform: scale(0.97);
    }
  }
}

// Size variants
.wc-button--large {
  padding: 11px 23px;
  @include global.text-button-large;
}
.wc-button--medium {
  padding: 9px 19px;
  @include global.text-button-medium;
}
.wc-button--small {
  padding: 7px 15px;
  @include global.text-button-small;
}
.wc-button--extra-small {
  padding: 5px 11px;
  @include global.text-button-extra-small;
}

// Primary variant
.wc-button--primary {
  color: theme("colors.white");
  border: 1px solid transparent;

  &.wc-button--disabled {
    color: theme("colors.white");
    background: theme("colors.neutral.30");
    border-color: theme("colors.neutral.30");
  }

  &:not(.wc-button--disabled) {
    &.wc-button--dark-mode {
      color: theme("colors.black");
    }

    &.wc-button--green {
      // Default light mode
      background: theme("colors.darktone.main");
      border-color: theme("colors.darktone.main");

      // Default dark mode
      &.wc-button--dark-mode {
        background: theme("colors.neutral.10");
        border-color: theme("colors.neutral.10");

        // Hover dark mode
        &:hover {
          background: theme("colors.neutral.20");
          border-color: theme("colors.neutral.20");
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.darktone.dark");
        border-color: theme("colors.darktone.dark");
      }
    }

    &.wc-button--sage {
      // Default light mode
      background: theme("colors.sagetone.dark");
      border-color: theme("colors.sagetone.dark");

      // Default dark mode
      &.wc-button--dark-mode {
        background: theme("colors.sagetone.lightbackground");
        border-color: theme("colors.sagetone.lightbackground");

        // Hover dark mode
        &:hover {
          background: theme("colors.sagetone.darkbackgroundhover");
          border-color: theme("colors.sagetone.darkbackgroundhover");
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.sagetone.hover");
        border-color: theme("colors.sagetone.hover");
      }
    }

    &.wc-button--alert {
      color: theme("colors.white");
      background: theme("colors.error.main");
      border-color: theme("colors.error.main");

      &:hover {
        background: theme("colors.error.dark");
        border-color: theme("colors.error.dark");
      }
    }

    &.wc-button--accent-yellow {
      // Dark mode (no light mode)
      &.wc-button--dark-mode {
        color: theme("colors.black");
        background: theme("colors.highlight.yellow.DEFAULT");
        border-color: theme("colors.highlight.yellow.DEFAULT");

        &:hover {
          background: theme("colors.highlight.yellow.hover");
          border-color: theme("colors.highlight.yellow.hover");
        }
      }
    }
  }
}

// Secondary variant
.wc-button--secondary {
  border: 1px solid;
  background: transparent;

  &.wc-button--disabled {
    color: theme("colors.neutral.50");
    border-color: theme("colors.neutral.30");

    &.wc-button--dark-mode {
      color: theme("colors.neutral.60");
      border-color: theme("colors.neutral.60");
    }
  }

  &:not(.wc-button--disabled) {
    &.wc-button--green {
      // Light mode
      color: theme("colors.darktone.main");
      border-color: theme("colors.darktone.main");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.neutral.10");
        border-color: theme("colors.neutral.10");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.darktone.lightbackground");
      }
    }

    &.wc-button--sage {
      // Light mode
      color: theme("colors.sagetone.dark");
      border-color: theme("colors.sagetone.main");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.sagetone.light");
        border-color: theme("colors.sagetone.light");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.sagetone.lightbackground");
      }
    }

    &.wc-button--alert {
      // Light mode
      color: theme("colors.error.main");
      border-color: theme("colors.error.main");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.neonred.500");
        border-color: theme("colors.neonred.500");

        // Hover dark mode
        &:hover {
          color: theme("colors.neonred.400");
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.darktone.lightbackground");
      }
    }

    &.wc-button--accent-yellow {
      // Dark mode (no light mode)
      &.wc-button--dark-mode {
        color: theme("colors.highlight.yellow.DEFAULT");
        border-color: theme("colors.highlight.yellow.DEFAULT");

        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }
}

// Tertiary variant
.wc-button--tertiary {
  border: 1px solid transparent;
  background: transparent;

  &.wc-button--disabled {
    color: theme("colors.neutral.50");

    &.wc-button--dark-mode {
      color: theme("colors.neutral.60");
    }
  }

  &:not(.wc-button--disabled) {
    &.wc-button--green {
      // Light mode
      color: theme("colors.darktone.main");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.neutral.10");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.darktone.lightbackground");
      }
    }

    &.wc-button--sage {
      // Light mode
      color: theme("colors.sagetone.dark");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.sagetone.light");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.sagetone.lightbackground");
      }
    }

    &.wc-button--alert {
      // Light mode
      color: theme("colors.error.main");

      // Dark mode
      &.wc-button--dark-mode {
        color: theme("colors.neonred.500");

        // Hover dark mode
        &:hover {
          color: theme("colors.neonred.400");
          background: rgba(255, 255, 255, 0.08);
        }
      }

      // Hover light mode
      &:hover:not(.wc-button--dark-mode) {
        background: theme("colors.darktone.lightbackground");
      }
    }

    &.wc-button--accent-yellow {
      // Dark mode (no light mode)
      &.wc-button--dark-mode {
        color: theme("colors.highlight.yellow.DEFAULT");

        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }
      }
    }
  }
}
</style>
