<template>
  <section class="flex justify-between gap-4">
    <div class="sticky top-4 flex h-[calc(100vh-8rem)] w-1/4 flex-col rounded-md bg-white shadow-md">
      <div class="flex h-full flex-col">
        <div class="border-b border-sagetone py-2">
          <div class="text-gray-600 relative mx-auto px-2 pt-2">
            <input
              v-model="accountNameFilter"
              class="border-gray-300 mb-2 h-10 rounded-lg border-2 bg-white px-5 pr-16 text-sm focus:outline-none"
              type="search"
              name="search"
              :disabled="adminAccountsStore.isLoading"
              placeholder="Filter accounts" />
          </div>
          <label class="my-2 flex flex-row items-center gap-4 px-4">
            Type
            <select v-model="accountTypeFilter" class="p-2">
              <option :value="null">Any</option>
              <option value="individual">Individuals</option>
              <option value="organization">Organizations</option>
            </select>
          </label>
        </div>
        <div class="h-full grow overflow-y-scroll">
          <Icon v-if="adminAccountsStore.isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
          <div class="grow" data-cy="accounts-list">
            <p v-if="filteredAccounts.length === 0" class="px-4 py-2 text-sagetone">No accounts match filters</p>
            <router-link
              v-for="account in filteredAccounts"
              :key="account.id"
              :data-cy="`nav-${account.name}`"
              :to="{ name: 'wc-admin-account', params: { accountId: account.id } }"
              class="flex w-full cursor-pointer justify-between px-4 py-2 text-left text-sm font-medium hover:bg-neutral-20 focus:outline-none focus-visible:ring focus-visible:ring-blue-40/75">
              <div class="flex w-full flex-col justify-between">
                <div class="flex w-full justify-between">
                  <span class="inline-block overflow-x-hidden text-ellipsis pr-2">{{ account.name }}</span>
                  <span class="inline-block">{{ account.id }}</span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="border-t-2 border-neutral-30">
          <router-link
            :to="{ name: 'wc-admin-account-new' }"
            class="flex w-full flex-row items-center justify-end p-4 disabled:cursor-not-allowed disabled:text-sagetone">
            <Icon icon="wc-carbon:add" class="mr-2" />
            New Account
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col">
      <router-view v-if="!adminAccountsStore.isLoading"></router-view>
      <Icon v-else icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { Icon } from "@iconify/vue"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { AccountType } from "@/models/models"

const adminAccountsStore = useAdminAccountsStore()

const accountNameFilter = ref<string>("")
const accountTypeFilter = ref<"individual" | "organization" | null>(null)
const filteredAccounts = computed(() =>
  adminAccountsStore.accounts
    .filter((account) => account.type !== AccountType.system)
    .filter(
      (account) =>
        account.name.toLowerCase().indexOf(accountNameFilter.value.toLowerCase()) !== -1 ||
        account.id.toString().indexOf(accountNameFilter.value) !== -1
    )
    .filter((account) => {
      switch (accountTypeFilter.value) {
        case null:
          return true
        case "individual":
        case "organization":
          return account.type == accountTypeFilter.value
      }
    })
)
</script>

<style scoped lang="scss">
.router-link-active {
  @apply bg-neutral-30;
}
</style>
