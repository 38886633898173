<template>
  <div class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <table v-if="!isLoading" class="w-full">
      <colgroup span="1" class="text-left"></colgroup>
      <colgroup span="3" class="border-r border-sagetone text-right"></colgroup>
      <colgroup span="4" class="border-r border-sagetone text-right"></colgroup>
      <colgroup span="3"></colgroup>

      <thead class="border-b border-sagetone">
        <tr>
          <th></th>
          <th colspan="3">Assets</th>
          <th colspan="4">Audits</th>
          <th colspan="3">EACs</th>
        </tr>
        <tr>
          <th class="text-left">Account</th>

          <th class="text-right">Draft</th>
          <th class="text-right">Ready</th>
          <th class="pr-2 text-right">Archived</th>

          <th class="text-right">Pending</th>
          <th class="text-right">Failed</th>
          <th class="text-right">Passed</th>
          <th class="pr-2 text-right">Last Run</th>

          <th class="text-right">Pending</th>
          <th class="text-right">Minted</th>
          <th class="pr-2 text-right">Last Mint</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="summary in summaries" :key="summary.account.id" class="even:bg-highlight-pastelazure">
          <td class="max-w-[20ch] truncate py-2 text-left underline">
            <router-link :to="{ name: 'wc-admin-account-assets', params: { accountId: summary.account.id } }">
              {{ summary.account.name }}
            </router-link>
          </td>

          <td class="py-2 text-right">
            {{ (summary.devices.statuses.draft + summary.devices.statuses.pending + summary.devices.statuses.changesRequested).toLocaleString() }}
          </td>
          <td class="py-2 text-right">{{ summary.devices.statuses.approved.toLocaleString() }}</td>
          <td class="py-2 pr-2 text-right">0</td>

          <td class="py-2 text-right">{{ (summary.audits.statuses.pending + summary.audits.statuses.running).toLocaleString() }}</td>
          <td class="py-2 text-right">{{ summary.audits.statuses.failed.toLocaleString() }}</td>
          <td class="py-2 text-right">{{ summary.audits.statuses.passed.toLocaleString() }}</td>
          <td class="py-2 pr-2 text-right">{{ summary.audits.latest ? formatDate(summary.audits.latest) : "—" }}</td>

          <td class="py-2 text-right">{{ ((summary.preliminaryEacs?.quantity ?? 0) - (summary.eacs?.quantity ?? 0)).toLocaleString() }}</td>
          <td class="py-2 text-right">{{ (summary.eacs?.quantity ?? 0).toLocaleString() }}</td>
          <td class="py-2 pr-2 text-right">{{ summary.eacs?.lastMinting ? formatDate(summary.eacs?.lastMinting) : "—" }}</td>
        </tr>
      </tbody>
    </table>
    <WcLoadingSpinner v-else />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useApiFetcher } from "@/services/service-container"
import { formatDate } from "@/utils/formatDate"
import type { Account } from "@/models/models"

import WcLoadingSpinner from "@/components/WcLoadingSpinner.vue"

const apiFetcher = useApiFetcher()

interface AccountSummary {
  account: Account
  devices: { statuses: { draft: number; pending: number; changesRequested: number; approved: number } }
  audits: { latest: string | null; statuses: { pending: number; running: number; failed: number; passed: number } }
  preliminaryEacs: { quantity: number } | null
  eacs: { quantity: number; lastMinting: string } | null
}

const isLoading = ref(true)
const summaries = ref<AccountSummary[]>([])

onMounted(async () => {
  try {
    summaries.value = await apiFetcher.httpGet("/admin/accounts", {})
  } finally {
    isLoading.value = false
  }
})
</script>
