<template>
  <AppPage>
    <section class="bg-background-sagelight">
      <AppPageHeader page-type-label="Portfolio" show-account-context backlink-text="Back to Dashboard" :backlink-route="{ name: 'wc-dashboard' }">
        <span class="text-subheading-large-bold text-wrap pt-3.5">{{ portfolio.name }}</span>
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-2 gap-y-8 pb-16 sm:grid-cols-3 lg:grid-cols-6">
        <WcDataWithLabel class="col-span-1">
          <template #label>Status</template>
          <template #content>
            <span class="capitalize">{{ portfolio.status }}</span>
          </template>
        </WcDataWithLabel>
        <WcDataWithLabel class="col-span-1">
          <template #label>Last Updated</template>
          <template #content>
            {{ latestUpdateTime ? formatDateAndTime(latestUpdateTime) : "" }}
          </template>
        </WcDataWithLabel>
        <WcDataWithLabel class="col-span-1">
          <template #label>Net Allocated</template>
          <template #content>
            {{ getFormattedEacQuantity(totalAllocated ?? 0, quantityUnits) }}
          </template>
        </WcDataWithLabel>
        <WcDataWithLabel v-if="isStakeholder" class="col-span-1">
          <template #label>Net Ordered</template>
          <template #content>
            {{ getFormattedEacQuantity(portfolio.relationships.stakeholder?.quantity ?? 0, quantityUnits) }}
          </template>
        </WcDataWithLabel>
        <WcDataWithLabel v-if="measurementParameter === EacMeasurementParameter.Electricity" class="col-span-1">
          <template #label>Carbon Impact</template>
          <template #content>
            {{ getFormattedEacQuantity(carbonImpact ?? 0, EacMeasurementParameter.GhgEmissions) }}
          </template>
        </WcDataWithLabel>
        <WcDataWithLabel v-if="isStakeholder && portfolio.relationships.stakeholder.paidPenniesUsd !== null" class="col-span-1">
          <template #label>Total Paid</template>
          <template #content>
            {{ formatCurrencyInPennies(portfolio.relationships.stakeholder.paidPenniesUsd) }}
          </template>
        </WcDataWithLabel>
      </div>
      <WcTabs>
        <WcTab :is-active="selectedTab === Tab.Assets" @click="selectedTab = Tab.Assets">Assets</WcTab>
        <WcTab :is-active="selectedTab === Tab.Observers" @click="selectedTab = Tab.Observers">Observers</WcTab>
      </WcTabs>
    </section>

    <section v-if="selectedTab === Tab.Assets" class="wc-page-container py-10">
      <div v-if="assetsByStoryId.size">
        <div v-for="[storyId, assetList] in assetsByStoryId" :key="storyId" class="wc-homepage-grid mb-12 border-neutral-30">
          <div class="col-span-4">
            <template v-if="stories.get(storyId).status === 'published'">
              <a :href="`${WWW_BASE_URL}/stories/${storyId}`" target="_blank">
                <h4 class="text-subheading-1-link">{{ stories.get(storyId).name }}</h4>
              </a>
            </template>
            <template v-else>
              <h4 class="text-subheading-1">{{ stories.get(storyId).name }}</h4>
            </template>
            <div v-if="stories.get(storyId).shortDescription">
              <p class="text-body-2 mt-4">{{ stories.get(storyId).shortDescription }}</p>
            </div>
          </div>
          <div class="col-span-8 md:-my-2">
            <div class="w-full overflow-x-scroll">
              <WcTable
                v-if="portfolio.units == 'wh_electricity'"
                class="w-full"
                :data="assetList"
                :columns="[
                  { key: 'id', label: 'Asset ID', align: 'left' },
                  { key: 'state', label: 'Location', align: 'left' },
                  { key: 'coordinates', label: 'Coordinates', align: 'left' },
                  { key: 'allocatedNetPortfolioUnits', label: 'Allocated EACs', align: 'left' },
                  { key: 'allocatedNetGco2e', label: 'Carbon Impact', align: 'left' },
                ]"
                row-id-field="id"
                table-class="text-body-3"
                th-class="text-left py-2"
                td-class="p-3"
                :sortable="false">
                <template #coordinates="{ row: { coordinates } }"> {{ coordinates.latitude }}°, {{ coordinates.longitude }}° </template>
                <template #allocatedNetPortfolioUnits="{ row: { allocation } }">
                  {{ getFormattedEacQuantity(allocation?.allocatedNetPortfolioUnits || 0, measurementParameter) }}
                </template>
                <template #allocatedNetGco2e="{ row: { allocation } }">
                  {{ getFormattedEacQuantity(allocation?.allocatedNetGco2e || 0, EacMeasurementParameter.GhgEmissions) }}
                </template>
              </WcTable>
              <WcTable
                v-else
                class="w-full"
                :data="assetList"
                :columns="[
                  { key: 'id', label: 'Asset ID', align: 'left' },
                  { key: 'state', label: 'Location', align: 'left' },
                  { key: 'coordinates', label: 'Coordinates', align: 'left' },
                  { key: 'allocatedSavings', label: 'Allocated Savings', align: 'left' },
                  { key: 'allocatedEmissions', label: 'Allocated Emissions', align: 'left' },
                  { key: 'netSavings', label: 'Net Savings', align: 'left' },
                ]"
                row-id-field="id"
                table-class="text-body-3"
                th-class="text-left py-2"
                td-class="p-3"
                :sortable="false">
                <template #coordinates="{ row: { coordinates } }"> {{ coordinates.latitude }}°, {{ coordinates.longitude }}° </template>
                <template #allocatedSavings="{ row: { allocation } }">
                  {{ getFormattedEacQuantity(allocation?.allocatedPositivePortfolioUnits || 0, measurementParameter) }}
                </template>
                <template #allocatedEmissions="{ row: { allocation } }">
                  {{ getFormattedEacQuantity(allocation?.allocatedNegativePortfolioUnits || 0, measurementParameter) }}
                </template>
                <template #netSavings="{ row: { allocation } }">
                  {{ getFormattedEacQuantity(allocation?.allocatedNetPortfolioUnits || 0, measurementParameter) }}
                </template>
              </WcTable>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <EmptyState title="No portfolio assets">
          Assets in this portfolio have not been registered yet. Contact
          <a class="text-hyperlink" href="mailto:support@wattcarbon.com">support@wattcarbon.com</a> to configure your portfolio.
        </EmptyState>
      </div>
    </section>

    <section v-if="selectedTab === Tab.Observers" class="wc-page-container py-10">
      <PortfolioObservers :portfolio-id="props.portfolioId" :observers="observers" />
    </section>
  </AppPage>
</template>

<script setup lang="ts">
// Libraries
import { computed, ref } from "vue"
import { getEnvironment } from "@/environment"
import formatCurrencyInPenniesFactory from "@/utils/formatCurrencyInPenniesFactory"
import { formatDateAndTime } from "@/utils/formatDateAndTime"

// Components
import { AppPage, AppPageHeader, WcTab, WcTabs } from "@/components/layout"
import EmptyState from "@/components/ui/EmptyState.vue"
import WcDataWithLabel from "@/components/ui/WcDataWithLabel.vue"
import WcTable from "@/components/WcTable/WcTable.vue"
import PortfolioObservers from "./components/PortfolioObservers.vue"

// Models
import { DeviceSummary, EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"

// Services
import { usePortfolioService } from "@/services/service-container"

enum Tab {
  Assets = "assets",
  Observers = "observers",
}

const { WWW_BASE_URL } = getEnvironment()

const props = defineProps<{
  portfolioId: string
}>()

const portfolioService = usePortfolioService()

const selectedTab = ref<Tab>(Tab.Assets)
const portfolio = await portfolioService.getPortfolio(props.portfolioId)
const observers = await portfolioService.listObservers(props.portfolioId)

const portfolioDeviceAllocations = computed(() => portfolio.devices.map(({ allocation }) => allocation))
const totalAllocated = computed(() =>
  portfolioDeviceAllocations.value.reduce((acc, { allocatedNetPortfolioUnits }) => acc + (allocatedNetPortfolioUnits || 0), 0)
)
const carbonImpact = computed(() => portfolioDeviceAllocations.value.reduce((acc, { allocatedNetGco2e }) => acc + (allocatedNetGco2e || 0), 0))
const quantityUnits = computed(() =>
  portfolio.units === "wh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
)
const isStakeholder = computed(() => portfolio.relationships.stakeholder !== null)
const latestUpdateTime = computed(() => {
  const sortedUpdateTimes = (portfolio.devices || [])
    .map(({ allocation }) => allocation.updatedTime)
    .concat([portfolio.updatedTime, portfolio.relationships.stakeholder?.updatedTime, portfolio.relationships.supplier?.updatedTime])
    .filter((time) => time)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  return sortedUpdateTimes[0]
})

const formatCurrencyInPennies = formatCurrencyInPenniesFactory()

const stories = computed(() => {
  const stories = new Map()
  portfolio.devices.forEach((asset) => {
    stories.set(asset.story.id, asset.story)
  })
  return stories
})

const assetsByStoryId = computed(() => {
  const assetsByStoryId = new Map<number, DeviceSummary[]>()
  portfolio.devices.forEach((asset) => {
    const storyId = asset.story.id
    const assetList = assetsByStoryId.get(storyId)
    if (assetList) {
      assetList.push(asset)
    } else {
      assetsByStoryId.set(storyId, [asset])
    }
  })
  return assetsByStoryId
})

const measurementParameter = computed(() => {
  return portfolio.units === "wh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
})
</script>
