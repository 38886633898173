import { computed, ref, watch } from "vue"
import { defineStore } from "pinia"
import type { AssetsSummary } from "@/models/asset"
import type { AccountBalanceSummary } from "@/services/api/certificate.model"
import type { Transaction } from "@/services/api/transaction.service"
import { ACTIVITY_LOG_INCLUDED_KINDS } from "@/services/api/transaction.service"
import { useAssetService, useCertificateService, useTransactionService } from "@/services/service-container"
import { useMainStore } from "@/store"
import { CertificateBalanceSummary, DateTimeRange } from "@/services/api/certificate.service"
import { AccountName } from "@/models/models"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

export const useDashboardMetricsStore = defineStore("dashboardMetrics", () => {
  const isLoading = ref<boolean>(true)

  const accountBalanceSummary = ref<AccountBalanceSummary>()
  const numAssets = ref<number>()
  const transactions = ref<Transaction[]>()
  const suppliers = ref<AccountName[]>([])
  const datetimeRange = ref<DateTimeRange | null>(null)

  const assetService = useAssetService()
  const certificateService = useCertificateService()
  const transactionService = useTransactionService()

  const mainStore = useMainStore()

  const accountId = computed(() => mainStore.account.id)

  const loadMetrics = async () => {
    isLoading.value = true
    const results = await Promise.allSettled([
      certificateService.getBalanceSummary({ aggregateBy: "all_time" }),
      assetService.getAssetsSummary(),
      transactionService.listTransactions({ kind: ACTIVITY_LOG_INCLUDED_KINDS, per_page: 1 }),
    ])

    const [balanceSummaryResponse, assetsSummaryResponse, transactionsResponse] = results.map((result) => {
      if (result.status === "fulfilled") {
        return result.value
      } else {
        console.error("Error loading dashboard metrics", result.reason)
        return undefined
      }
    })

    if (balanceSummaryResponse) {
      const summary = balanceSummaryResponse as CertificateBalanceSummary
      accountBalanceSummary.value = summary.balances.reduce(
        (acc, row) => {
          let count = acc[row.subaccountKind]?.[row.units] ?? 0
          count += row.balance
          acc[row.subaccountKind][row.units] = count
          return acc
        },
        { active: {}, retirement: {} } as AccountBalanceSummary
      )

      datetimeRange.value = summary.balances.reduce(
        (acc, row) => {
          if (acc.lower === "" || parseUTCTimestamp(row.interval.lower) < parseUTCTimestamp(acc.lower)) {
            acc.lower = row.interval.lower
          }

          if (acc.upper === "" || parseUTCTimestamp(row.interval.upper) > parseUTCTimestamp(acc.upper)) {
            acc.upper = row.interval.upper
          }
          return acc
        },
        { lower: "", upper: "" } as DateTimeRange
      )

      suppliers.value = summary.suppliers
    }
    numAssets.value = assetsSummaryResponse && (assetsSummaryResponse as AssetsSummary)?.assetCount
    transactions.value = transactionsResponse && (transactionsResponse as { data: Transaction[] })?.data
  }

  const $reset = () => {
    isLoading.value = true
    accountBalanceSummary.value = undefined
    numAssets.value = undefined
    transactions.value = undefined
  }

  watch(
    accountId,
    () => {
      $reset()
      loadMetrics()
    },
    { immediate: true }
  )

  return {
    // state
    isLoading,
    accountBalanceSummary,
    numAssets,
    transactions,
    suppliers,
    datetimeRange,
    // actions
    loadMetrics,
    $reset,
  }
})
