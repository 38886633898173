<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <div class="wc-markdown" v-html="renderedSource" />
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { createMarkdownRenderer } from "./WcMarkdownRenderer"

const md = createMarkdownRenderer()

const renderMarkdown = (source: string | undefined) => {
  if (!source) {
    return ""
  }
  return md.render(source)
}

const { source } = defineProps<{ source: string | undefined }>()
const renderedSource = computed(() => renderMarkdown(source))
</script>

<style lang="scss">
.wc-markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-black mb-2 mt-4;
  }

  @apply text-[16px] leading-[24px] text-black;

  p {
    @apply text-[16px] leading-[24px] text-black;
  }

  // Include 16px spacing between p, ul, and ol elements
  :where(p, ul, ol) + :where(p, ul, ol) {
    @apply mt-4;
  }

  // Include 16px spacing between these elements when nested
  li :where(p + :where(p, ul, ol)) {
    @apply mt-4;
  }

  // Left-align LaTeX blocks instead of center-aligning them
  .katex-display {
    text-align: left;
    > .katex {
      text-align: left;
    }
  }

  a {
    text-decoration: underline;
    @apply text-black;
  }

  li {
    list-style-position: outside;
    padding-left: 0.25em;
  }

  ol,
  ul {
    padding-left: 1.25em;
  }
  ul > li {
    list-style-type: disc;
  }

  ol > li {
    list-style-type: auto;
  }
  ol[type="a"] > li {
    list-style-type: lower-alpha;
  }

  details {
    @apply mb-8;
  }

  summary {
    @apply cursor-pointer text-[16px] leading-[24px] text-black font-[600] pb-3 border-b border-neutral-40;
    position: relative;
    list-style: none;
  }
  details[open] summary {
    @apply mb-6;
  }

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
  }

  summary::after {
    content: "∧";
    position: absolute;
    right: 0.375rem;
    top: -1px;
    transform: translateY(12.5%) rotate(180deg) scaleX(1.5);
    font-size: 14px;
    font-weight: 800;
  }

  details[open] summary::after {
    transform: translateY(0%) rotate(0deg) scaleX(1.5);
  }
}
</style>
