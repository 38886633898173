import ApiFetcher from "@/services/api-fetcher"
import {
  Portfolio,
  PortfolioWithSummary,
  PortfolioWithDetails,
  PortfolioAllocation,
  PortfolioStakeholderRelationship,
  PortfolioSupplierRelationship,
  PortfolioObserverRelationship,
} from "@/models/order"

export default class PortfolioService {
  fetcher: ApiFetcher
  constructor(fetcher: ApiFetcher) {
    this.fetcher = fetcher
  }

  listPortfolios = async (options: { accountId?: number } = {}): Promise<PortfolioWithSummary[]> => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpGet<PortfolioWithSummary[]>(endpoint, options)
  }
  getPortfolio = async (id: string): Promise<PortfolioWithDetails> => {
    // can include switch statements for the parameter
    const endpoint = `/portfolios/${id}`
    return await this.fetcher.httpGet<PortfolioWithDetails>(endpoint, {})
  }
  listPortfoliosAdmin = async (): Promise<Array<Portfolio>> => {
    const endpoint = "/portfolios/admin"
    return await this.fetcher.httpGet<Array<Portfolio>>(endpoint, {})
  }
  addPortfolio = async (portfolioRequest: Portfolio) => {
    const endpoint = "/portfolios"
    return await this.fetcher.httpPost<Portfolio>(endpoint, portfolioRequest)
  }
  updatePortfolio = async (id: string, portfolioRequest: Portfolio) => {
    const endpoint = `/portfolios/${id}`
    return await this.fetcher.httpPut<Portfolio>(endpoint, portfolioRequest)
  }
  deletePortfolio = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}`
    return await this.fetcher.httpDelete(endpoint, {})
  }

  attachSupplier = async (id: string, supplierId: number, quantity: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    return await this.fetcher.httpPut<PortfolioSupplierRelationship>(endpoint, { quantity })
  }
  listSuppliers = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/suppliers`
    return await this.fetcher.httpGet<PortfolioSupplierRelationship[]>(endpoint, {})
  }
  detachSupplier = async (id: string, supplierId: number) => {
    const endpoint = `/portfolios/${id}/suppliers/${supplierId}`
    await this.fetcher.httpDelete(endpoint)
  }

  attachObserver = async (id: string, observerId: number) => {
    const endpoint = `/portfolios/${id}/observers/${observerId}`
    return await this.fetcher.httpPut<PortfolioObserverRelationship>(endpoint, {})
  }
  attachObserverAdmin = async (id: string, observerId: number, referrerId: number) => {
    const endpoint = `/portfolios/${id}/observers/${observerId}/admin`
    return await this.fetcher.httpPut<PortfolioObserverRelationship>(endpoint, { referrerId })
  }
  attachObserverByEmail = async (id: string, email: string) => {
    const endpoint = `/portfolios/${id}/observers`
    return await this.fetcher.httpPost<PortfolioObserverRelationship>(endpoint, { email })
  }
  listObservers = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/observers`
    return await this.fetcher.httpGet<PortfolioObserverRelationship[]>(endpoint, {})
  }
  listObserversAdmin = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/observers/admin`
    return await this.fetcher.httpGet<PortfolioObserverRelationship[]>(endpoint, {})
  }
  detachObserver = async (id: string, observerId: number) => {
    const endpoint = `/portfolios/${id}/observers/${observerId}`
    await this.fetcher.httpDelete(endpoint)
  }

  updateStake = async (portfolioId: string, stakeholderId: number, quantity: number, paidPenniesUsd: number) => {
    const endpoint = `/portfolios/${portfolioId}/stakes/${stakeholderId}`
    return await this.fetcher.httpPut<PortfolioStakeholderRelationship>(endpoint, { quantity, paidPenniesUsd })
  }
  listStakes = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/stakes`
    return await this.fetcher.httpGet<PortfolioStakeholderRelationship[]>(endpoint, {})
  }
  removeStake = async (portfolioId: string, stakeholderId: number) => {
    const endpoint = `/portfolios/${portfolioId}/stakes/${stakeholderId}`
    await this.fetcher.httpDelete(endpoint, {})
  }

  getAllocations = async (portfolioId: string) => {
    const endpoint = `/portfolios/${portfolioId}/allocations`
    return await this.fetcher.httpGet<PortfolioAllocation[]>(endpoint, {})
  }
}
