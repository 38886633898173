<template>
  <div>
    <div class="text-overline-2 mb-4">
      <slot name="label"></slot>
    </div>
    <div class="text-body-2">
      <slot name="content"></slot>
    </div>
  </div>
</template>
