<template>
  <WcModal :is-open="isOpen" header="Invite an observer to view this portfolio" @update:is-open="handleUpdateIsOpen">
    <template #panel>
      <form ref="formRef" @submit.prevent="inviteObserver">
        <div class="text-body-2 mb-8">The observer will receive an email with a link to view the portfolio.</div>
        <div class="sm:w-[424px]">
          <fieldset class="mb-8">
            <label class="text-body-2 mb-2 text-black" for="observerEmail">Email</label>
            <WcInputText v-model="observerEmail" name="observerEmail" type="email" autocomplete="off" />
          </fieldset>
        </div>
      </form>
    </template>
    <template #footer>
      <WcButton text="Cancel" class="ml-auto" variant="secondary" size="small" @click="handleUpdateIsOpen(false)" />
      <WcButton text="Send Invite" :is-disabled="!observerEmail" size="small" type="submit" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import { WcButton } from "@/components/button"
import { WcInputText } from "@/components/input"
import WcModal from "@/components/WcModal.vue"
import { usePortfolioService } from "@/services/service-container"

const portfolioService = usePortfolioService()
const toast = useToast()

const props = defineProps<{ portfolioId: string }>()

const emit = defineEmits(["observer-invited"])

const formRef = ref<HTMLFormElement | null>(null)
const observerEmail = ref<string>("")
const isOpen = ref<boolean>(false)

const handleUpdateIsOpen = (newValue: boolean) => {
  isOpen.value = newValue
  if (!newValue) {
    resetModal()
  }
}

const openModal = () => handleUpdateIsOpen(true)
const closeModal = () => handleUpdateIsOpen(false)
defineExpose({ openModal, closeModal })

const inviteObserver = async () => {
  try {
    await portfolioService.attachObserverByEmail(props.portfolioId, observerEmail.value)
    emit("observer-invited", observerEmail.value)
    toast.success(`Invitation sent. ${observerEmail.value} can now view this portfolio.`)
    closeModal()
  } catch (error) {
    console.error(error)
    toast.error("There was a problem inviting the observer. Please try again.")
  }
}

const resetModal = () => {
  observerEmail.value = ""
}
</script>
