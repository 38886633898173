import { authorizedRoute } from "@/services/auth/authorized.route-guard"
import { RouteRecordRaw } from "vue-router"
import { AppPortal } from "@/models/models"
import AppLayout from "@/components/layout/AppLayout.vue"
import ManageEACs from "@/modules/eacs/ManageEACs.vue"
import ManageEACsDetail from "@/modules/eacs/ManageEACsDetail.vue"

export const eacRoutes: Array<RouteRecordRaw> = [
  {
    path: "/eacs",
    name: "wc-eacs",
    component: ManageEACs,
    meta: { layout: AppLayout, appPortal: AppPortal.Dashboard },
    redirect: { name: "wc-eacs-select-eacs" },
    beforeEnter: authorizedRoute,
    children: [{ name: "wc-eacs-select-eacs", path: "./", component: ManageEACsDetail }],
  },
]
