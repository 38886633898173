<template>
  <div class="-mr-2 inline-flex shrink-0">
    <component
      :is="componentIs"
      :aria-label="title"
      :class="[
        'wc-icon-button flex grow items-baseline justify-center gap-2 rounded-full transition-all ease-in-out',
        `wc-icon-button--${color}`,
        `wc-icon-button--${size}`,
        {
          'wc-icon-button--dark-mode': darkMode,
          'wc-icon-button--disabled': isDisabled,
        },
        buttonClasses,
      ]"
      :disabled="isDisabled"
      :title="title"
      v-bind="componentSpecificProps">
      <Icon :icon="icon" />
    </component>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Icon } from "@iconify/vue"
import type { ButtonSize, ButtonType } from "./WcButton"

type BaseProps = {
  buttonClasses?: string
  color?: "default" | "green" | "sage"
  darkMode?: boolean
  icon: string
  isDisabled?: boolean
  size?: ButtonSize
  title?: string
}

type ButtonProps = BaseProps & {
  href?: never
  openInNewTab?: never
  to?: never
  type?: ButtonType
}

type AnchorProps = BaseProps & {
  href: string
  openInNewTab?: boolean
  to?: never
  type?: never
}

type RouterLinkProps = BaseProps & {
  href?: never
  openInNewTab?: boolean
  to: string | object
  type?: never
}

export type Props = ButtonProps | AnchorProps | RouterLinkProps

const props = withDefaults(defineProps<Props>(), {
  color: "default",
  darkMode: false,
  isDisabled: false,
  size: "large",
  type: "button",
})

const componentIs = computed(() => {
  if (props.href !== undefined || props.type === "anchor") {
    return "a"
  } else if (props.to) {
    return "router-link"
  } else {
    return "button"
  }
})
const componentSpecificProps = computed(() => {
  if (props.href || props.type === "anchor") {
    return {
      href: props.href,
      target: props.openInNewTab ? "_blank" : undefined,
    }
  }

  if (props.to) {
    return { to: props.to, target: props.openInNewTab ? "_blank" : undefined }
  }

  return { type: props.type }
})
</script>

<style scoped lang="scss">
.wc-icon-button {
  &:not(.wc-icon-button--disabled) {
    cursor: pointer;
    &:active {
      transform: scale(0.97);
    }
  }
}

// Size variants
.wc-icon-button--large {
  font-size: 32px;
  padding: 12px;
}
.wc-icon-button--medium {
  font-size: 24px;
  padding: 8px;
}
.wc-icon-button--small {
  font-size: 20px;
  padding: 6px;
}
.wc-icon-button--extra-small {
  font-size: 16px;
  padding: 6px;
}

.wc-icon-button {
  &.wc-icon-button--disabled {
    &.wc-icon-button--dark-mode {
      color: theme("colors.neutral.60");
    }
    &:not(.wc-icon-button--dark-mode) {
      color: theme("colors.neutral.50");
    }
  }

  &:not(.wc-icon-button--disabled) {
    &.wc-icon-button--default {
      // Default light mode
      color: theme("colors.black");

      // Default dark mode
      &.wc-icon-button--dark-mode {
        color: theme("colors.darktone.lightbackground");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }

      // Hover light mode
      &:hover:not(.wc-icon-button--dark-mode) {
        background: theme("colors.neutral.20");
      }
    }

    &.wc-icon-button--green {
      // Default light mode
      color: theme("colors.darktone.main");

      // Default dark mode
      &.wc-icon-button--dark-mode {
        color: theme("colors.darktone.lightbackground");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }

      // Hover light mode
      &:hover:not(.wc-icon-button--dark-mode) {
        background: theme("colors.neutral.20");
      }
    }

    &.wc-icon-button--sage {
      // Default light mode
      color: theme("colors.sagetone.dark");

      // Default dark mode
      &.wc-icon-button--dark-mode {
        color: theme("colors.sagetone.light");

        // Hover dark mode
        &:hover {
          background: rgba(255, 255, 255, 0.12);
        }
      }

      // Hover light mode
      &:hover:not(.wc-icon-button--dark-mode) {
        background: theme("colors.neutral.20");
      }
    }
  }
}
</style>
