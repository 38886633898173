<template>
  <h3 class="text-subheading-large-bold">Portfolio Observers</h3>
  <div class="text-body-2 mt-3">Observers are given access to see the same portfolio details as the account that invited them.</div>
  <div v-if="observers.length > 0" class="mt-8">
    <div v-for="observer in observers" :key="observer.name" class="my-3 flex items-center gap-2">
      <span>{{ observer.name }}</span>
      <WcIconButton
        class="text-error"
        icon="wc-carbon:trash-can"
        title="Remove observer"
        size="extra-small"
        @click="handleRemoveObserver(observer)" />
    </div>
    <WcButton text="Invite an Observer" class="mt-8" size="medium" @click="inviteObserverModal.openModal()" />
  </div>
  <EmptyState v-else title="No portfolio observers">
    <a class="text-hyperlink" @click="inviteObserverModal.openModal()">Invite an observer</a> to give them access to your portfolio.
  </EmptyState>
  <PortfolioInviteObserverModal ref="inviteObserverModal" :portfolio-id="portfolioId" @observer-invited="loadObservers()" />
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useToast } from "vue-toastification"
import { WcButton, WcIconButton } from "@/components/button"
import { useConfirm } from "@/components/confirm/ConfirmContext"
import EmptyState from "@/components/ui/EmptyState.vue"
import { PortfolioObserverRelationship } from "@/models/order"
import { usePortfolioService } from "@/services/service-container"
import PortfolioInviteObserverModal from "./PortfolioInviteObserverModal.vue"

const props = defineProps<{
  observers: PortfolioObserverRelationship[]
  portfolioId: string
}>()

const portfolioService = usePortfolioService()
const { confirm } = useConfirm()
const toast = useToast()

const inviteObserverModal = ref()
const observers = ref<PortfolioObserverRelationship[]>(props.observers)

const loadObservers = async () => {
  observers.value = await portfolioService.listObservers(props.portfolioId)
}

const handleRemoveObserver = async (observer: PortfolioObserverRelationship) => {
  if (
    observer &&
    (await confirm({
      title: `Remove ${observer.name}`,
      message: "Are you sure you want to remove this observer? They will lose the ability to access the portfolio.",
      confirmText: "Remove Observer",
    }))
  ) {
    try {
      await portfolioService.detachObserver(props.portfolioId, observer.accountId)
      toast.success(`${observer.name} was removed from the portfolio.`)
      loadObservers()
    } catch (error) {
      toast.error("There was a problem removing the observer from the portfolio. Please try again.")
      throw error
    }
  }
}
</script>
