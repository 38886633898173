import { defineStore } from "pinia"
import { Portfolio, PortfolioStakeholderRelationship, PortfolioObserverRelationship, PortfolioSupplierRelationship } from "@/models/order"
import { ref, computed } from "vue"
import { TYPE, useToast } from "vue-toastification"
import { usePortfolioService } from "@/services/service-container"
import { AccountName } from "@/models/models"

export const useAdminPortfoliosStore = defineStore("adminPortfolios", () => {
  const isLoading = ref<boolean>(true)
  const isSaving = ref<boolean>(false)
  const portfoliosMap = ref<Map<string, Portfolio>>(new Map())

  const portfolioService = usePortfolioService()
  const toast = useToast()

  const loadPortfolios = async () => {
    isLoading.value = true
    try {
      const portfoliosArray = await portfolioService.listPortfoliosAdmin()
      portfoliosMap.value = new Map(portfoliosArray.map((p) => [p.id, p]))
    } finally {
      isLoading.value = false
    }
  }

  const _toastify = <T extends Array<any>, U>(message: string, fn: (...args: T) => U) => {
    return async (...args: T): Promise<U> => {
      isSaving.value = true
      const toastId = toast(`${message}…`, { id: "Portfolios", type: TYPE.DEFAULT, timeout: false })

      try {
        const result = await fn(...args)
        toast.update(toastId, { content: "Success!", options: { type: TYPE.SUCCESS, timeout: 1000 } })
        return result
      } catch (err) {
        toast.update(toastId, { content: `Error: ${err}`, options: { type: TYPE.ERROR } })
        throw err
      } finally {
        isSaving.value = false
      }
    }
  }

  const fetchPortfolio = async (portfolioId: string) => {
    portfoliosMap.value.set(portfolioId, await portfolioService.getPortfolio(portfolioId))
  }

  const createPortfolio = _toastify("Saving", async (portfolio: Portfolio) => {
    const newPortfolio = await portfolioService.addPortfolio(portfolio)
    portfoliosMap.value.set(newPortfolio.id, newPortfolio)
    return newPortfolio
  })

  const updatePortfolio = _toastify("Saving", async (id: string, portfolio: Portfolio) => {
    const updatedPortfolio = await portfolioService.updatePortfolio(id, portfolio)
    portfoliosMap.value.delete(id)
    portfoliosMap.value.set(updatedPortfolio.id, updatedPortfolio)
    return updatedPortfolio
  })

  const deletePortfolio = _toastify("Deleting", async (portfolio: Portfolio) => {
    await portfolioService.deletePortfolio(portfolio.id)
    portfoliosMap.value.delete(portfolio.id)
  })

  const addSupplier = _toastify("Adding", async (portfolio: Portfolio, supplier: AccountName, quantity: number) => {
    return await portfolioService.attachSupplier(portfolio.id, supplier.id, quantity)
  })

  const listSuppliers = async (portfolioId: string) => {
    return await portfolioService.listSuppliers(portfolioId)
  }

  const removeSupplier = _toastify("Removing", async (portfolio: Portfolio, supplier: PortfolioSupplierRelationship) => {
    await portfolioService.detachSupplier(portfolio.id, supplier.accountId)
  })

  const addObserver = _toastify("Adding", async (portfolio: Portfolio, observer: AccountName, referrer: AccountName) => {
    return await portfolioService.attachObserverAdmin(portfolio.id, observer.id, referrer.id)
  })

  const listObservers = async (portfolioId: string) => {
    return await portfolioService.listObserversAdmin(portfolioId)
  }

  const removeObserver = _toastify("Removing", async (portfolio: Portfolio, observer: PortfolioObserverRelationship) => {
    await portfolioService.detachObserver(portfolio.id, observer.accountId)
  })

  const addStake = _toastify("Adding", async (portfolio: Portfolio, stakeholder: AccountName, quantity: number, paidPenniesUsd: number) => {
    return await portfolioService.updateStake(portfolio.id, stakeholder.id, quantity, paidPenniesUsd)
  })

  const listStakes = async (portfolioId: string) => {
    return await portfolioService.listStakes(portfolioId)
  }

  const removeStake = _toastify("Removing", async (portfolio: Portfolio, stakeholder: PortfolioStakeholderRelationship) => {
    await portfolioService.removeStake(portfolio.id, stakeholder.accountId)
  })

  const portfolios = computed(() => Array.from(portfoliosMap.value.values()))

  const getPortfolioById = computed(() => {
    return (id: string) => portfoliosMap.value.get(id)
  })

  // automatically load portfolios on first instantiation
  loadPortfolios()

  return {
    // state
    isLoading,
    isSaving,
    // actions
    loadPortfolios,
    fetchPortfolio,
    createPortfolio,
    updatePortfolio,
    deletePortfolio,
    addSupplier,
    listSuppliers,
    removeSupplier,
    addObserver,
    listObservers,
    removeObserver,
    addStake,
    listStakes,
    removeStake,
    // getters
    portfolios,
    getPortfolioById,
  }
})
