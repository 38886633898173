import { ref, computed, watch } from "vue"
import { useCertificateService } from "@/services/service-container"
import { CertificateBalanceSummary, DateRangeQuery } from "@/services/api/certificate.service"
import { defineStore } from "pinia"
import { addDays } from "date-fns"
import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

export interface EacBalances {
  [isoDateString: string]: EacBalance
}

export interface EacBalance {
  dateTime: string // this will be the same value as the key (isoDateString)
  dateIndex: number
  hourIndex: number
  active: number
  retirement: number
}

export interface EacMonthlyBalance {
  month: number
  year: number
  active: number
  retired: number
}

interface EacRangeSelection {
  minDateIndex: number
  maxDateIndex: number
  minHourIndex: number
  maxHourIndex: number
}

export type NullableEacRangeSelection = EacRangeSelection | null

export const isRetired = (eac: any) => {
  return eac["Status"] === "retired"
}

export const useEacStore = defineStore("eac", () => {
  const certificateService = useCertificateService()
  const isLoading = ref(true)

  const dateRange = ref<DateRangeQuery | null>(null)
  const eacBalances = ref<EacBalances>({})
  const selection = ref<EacRangeSelection | null>(null)

  const setDateRange = (newDateRange: DateRangeQuery) => {
    dateRange.value = newDateRange
    resetSelection()
  }

  watch(dateRange, async () => {
    await fetchEacBalances()
  })

  const fetchEacBalances = async () => {
    if (dateRange.value) {
      isLoading.value = true

      const summary = await certificateService.getBalanceSummary({
        aggregateBy: "hour",
        firstDatetime: dateRange.value.startDate,
        lastDatetime: dateRange.value.endDate,
      })
      eacBalances.value = balancesByHour(summary, dateRange.value.startDate)

      isLoading.value = false
    }
  }

  const isBalanceSelected = (balance: EacBalance) => {
    if (!selection.value) return false
    const { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } = selection.value
    return (
      minHourIndex <= balance.hourIndex && balance.hourIndex <= maxHourIndex && minDateIndex <= balance.dateIndex && balance.dateIndex <= maxDateIndex
    )
  }

  const isDateTimeSelected = (isoDateString: string) => {
    const balance = eacBalances.value[isoDateString]
    return isBalanceSelected(balance)
  }

  const setSelection = (newSelection: EacRangeSelection) => {
    selection.value = newSelection
  }

  const resetSelection = () => {
    selection.value = null
  }

  const selectedDateTimeRange = computed(() => {
    if (!dateRange.value) return null
    if (!selection.value) return null

    return {
      startDate: addDays(dateRange.value.startDate, selection.value.minDateIndex),
      endDate: addDays(dateRange.value.startDate, selection.value.maxDateIndex + 1),
      startHour: selection.value.minHourIndex,
      endHour: selection.value.maxHourIndex + 1,
    }
  })

  const totalAvailableQuantity = computed(() => {
    return Object.values(eacBalances.value).reduce((total, hour) => total + hour.active + hour.retirement, 0)
  })

  const selectedBalances = computed(() => Object.values(eacBalances.value).filter((balance) => isBalanceSelected(balance)))

  const totalSelectedQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active + balance.retirement, 0))

  const totalSelectedActiveQuantity = computed(() => selectedBalances.value.reduce((total, balance) => total + balance.active, 0))

  return {
    // state
    eacBalances,
    isLoading,
    dateRange,
    // getters
    totalAvailableQuantity,
    totalSelectedQuantity,
    totalSelectedActiveQuantity,
    isDateTimeSelected,
    selectedDateTimeRange,
    // actions
    setDateRange,
    setSelection,
    resetSelection,
    fetchEacBalances,
  }
})

// Pick out the year, month, and date portions of the Date and construct a new
// UTC Date with only those pieces
function constructUtcDateWithoutTime(utcDateWithTime: Date) {
  return new Date(Date.UTC(utcDateWithTime.getUTCFullYear(), utcDateWithTime.getUTCMonth(), utcDateWithTime.getUTCDate()))
}

function dateDiffInDays(startDate: Date, endDate: Date) {
  const dateDiff = endDate.getTime() - startDate.getTime()
  return Math.floor(dateDiff / (1000 * 3600 * 24))
}

export const balancesByHour = (summary: CertificateBalanceSummary, startDate: Date) => {
  if (!summary.balances) return {}

  const dateRangeStartUtcDateWithoutTime = constructUtcDateWithoutTime(startDate)

  return summary.balances.reduce((acc, balance) => {
    const hour = parseUTCTimestamp(balance.interval.lower)
    const utcDateWithoutTime = constructUtcDateWithoutTime(hour)
    const dateIndex = dateDiffInDays(dateRangeStartUtcDateWithoutTime, utcDateWithoutTime)
    const dateTime = hour.toISOString()

    let active = acc[dateTime]?.active ?? 0
    let retirement = acc[dateTime]?.retirement ?? 0
    if (balance.subaccountKind == "active") {
      active += balance.balance
    } else {
      retirement += balance.balance
    }

    acc[dateTime] = {
      dateTime,
      dateIndex,
      hourIndex: hour.getUTCHours(),
      active,
      retirement,
    }
    return acc
  }, {} as EacBalances)
}
