<template>
  <div class="grid grid-cols-6">
    <div class="col-span-2">
      <div class="mb-4 flex items-center">
        <h3 class="text-overline-2 inline-block">Active</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white" button-class="!size-[11px] -mt-0.5 ml-2 !text-hint !border-hint">
          The total amount of EACs you own but have not yet retired. Retire to claim against your emissions goals.
        </ToolTip>
      </div>
      <div>
        <div class="mb-1">
          <span class="text-featured-number break-words pr-2" data-cy="total-active">
            {{ activeEacs.quantity }}
          </span>
          <span>
            {{ activeEacs.unit }}
          </span>
        </div>
        <div v-if="activeElectricity.quantity || 0 > 0 || activeEmissions.quantity || 0 > 0" class="text-body-3">
          {{ activeElectricity.quantity }} {{ activeElectricity.unit }} + {{ activeEmissions.quantity }} {{ activeEmissions.unit }}
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="mb-4 flex items-center">
        <h3 class="text-overline-2 inline-block">Retired</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white" button-class="!size-[11px] -mt-0.5 ml-2 !text-hint !border-hint">
          The total amount of EACs you have retired (canceled). These can be claimed against your emissions goals.
        </ToolTip>
      </div>
      <div>
        <div class="mb-1">
          <span class="text-featured-number break-words pr-2" data-cy="total-retired">
            {{ retiredEacs.quantity }}
          </span>
          <span>
            {{ retiredEacs.unit }}
          </span>
        </div>
        <div v-if="retiredElectricity.quantity || 0 > 0 || retiredEmissions.quantity || 0 > 0" class="text-body-3">
          {{ retiredElectricity.quantity }} {{ retiredElectricity.unit }} + {{ retiredEmissions.quantity }} {{ retiredEmissions.unit }}
        </div>
      </div>
    </div>
    <div class="col-span-2">
      <div class="mb-4 flex items-center">
        <h3 class="text-overline-2 inline-block">Procured</h3>
        <ToolTip class="ph-no-capture inline-block" panel-class="bg-white" button-class="!size-[11px] -mt-0.5 ml-2 !text-hint !border-hint">
          The total amount of EACs ordered from suppliers.
        </ToolTip>
      </div>
      <div class="mb-1">
        <span class="text-featured-number break-words pr-2" data-cy="total-purchased">
          {{ totalPurchasedAmount.quantity }}
        </span>
        <span> {{ totalPurchasedAmount.unit }} </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import { usePortfolioService } from "@/services/service-container"
import { useDashboardMetricsStore } from "@/modules/dashboard/dashboard.state"

// Models
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"

const dashboardMetricsStore = useDashboardMetricsStore()

const portfolioService = usePortfolioService()
const portfolios = await portfolioService.listPortfolios()

const totalPurchasedAmount = computed(() => {
  const numPortfolioStakeholderEACs = portfolios.reduce((total, portfolio) => total + (portfolio.relationships?.stakeholder?.quantity ?? 0), 0)
  return getFormattedEacQuantity(numPortfolioStakeholderEACs, EacMeasurementParameter.EACs)
})

const activeEacs = computed(() => {
  const numActiveEacs =
    (dashboardMetricsStore.accountBalanceSummary?.active.wh_electricity_supplied ?? 0) +
    (dashboardMetricsStore.accountBalanceSummary?.active.g_co2e_avoided ?? 0)
  return getFormattedEacQuantity(numActiveEacs, EacMeasurementParameter.EACs)
})
const activeElectricity = computed(() => {
  return getFormattedEacQuantity(
    dashboardMetricsStore.accountBalanceSummary?.active.wh_electricity_supplied ?? 0,
    EacMeasurementParameter.Electricity
  )
})
const activeEmissions = computed(() => {
  return getFormattedEacQuantity(dashboardMetricsStore.accountBalanceSummary?.active.g_co2e_avoided ?? 0, EacMeasurementParameter.GhgEmissions)
})
const retiredEacs = computed(() => {
  const numRetiredEacs =
    (dashboardMetricsStore.accountBalanceSummary?.retirement.wh_electricity_supplied ?? 0) +
    (dashboardMetricsStore.accountBalanceSummary?.retirement.g_co2e_avoided ?? 0)
  return getFormattedEacQuantity(numRetiredEacs, EacMeasurementParameter.EACs)
})
const retiredElectricity = computed(() => {
  return getFormattedEacQuantity(
    dashboardMetricsStore.accountBalanceSummary?.retirement.wh_electricity_supplied ?? 0,
    EacMeasurementParameter.Electricity
  )
})
const retiredEmissions = computed(() => {
  return getFormattedEacQuantity(0, EacMeasurementParameter.GhgEmissions, 0)
})
</script>
