<template>
  <IconField v-if="icon" class="w-full" :class="inputClass" :icon-position="iconPosition">
    <InputIcon
      class="mt-[-10px] stroke-blue-70 stroke-1"
      :class="{ 'mt-[-6px]': size === 'xsmall', 'mt-[-8px]': size === 'small' || size === 'medium' }">
      <Icon :class="{ 'size-4': size === 'small', 'size-5': size === 'large' }" :icon="icon" />
    </InputIcon>
    <InputText
      v-model="model"
      option-label="label"
      :placeholder="insetLabel"
      :name="name"
      class="w-full"
      :class="{ '!pl-9': icon && iconPosition === 'left', '!pr-9': icon && iconPosition === 'right' }" />
  </IconField>
  <InputText
    v-else
    v-model="model"
    :autocomplete="autocomplete"
    option-label="label"
    :placeholder="insetLabel"
    :name="name"
    class="w-full"
    :type="type"
    :required="required"
    :class="inputClass" />
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue"
import IconField from "primevue/iconfield"
import InputIcon from "primevue/inputicon"
import InputText from "primevue/inputtext"
import type { InputSize } from "@/components/input"
import { useInputClass } from "@/components/input"

const model = defineModel<string | null>()
const props = withDefaults(
  defineProps<{
    autocomplete?: "off"
    icon?: string
    iconPosition?: "left" | "right"
    insetLabel?: string
    name: string
    required?: boolean
    size?: InputSize
    type?: "email"
  }>(),
  { iconPosition: "right" }
)
const inputClass = useInputClass(props)
</script>
