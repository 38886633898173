import MarkdownIt from "markdown-it"
import markdownItKatex from "@vscode/markdown-it-katex"
import MarkdownItCollapsible from "markdown-it-collapsible"
import { markdownItFancyListPlugin } from "markdown-it-fancy-lists"

// The module only provides a CommonJS export, which Node.js wraps in a "default"
// property during SSR. We need to handle both this case and Vite's ESM interop
// which exposes the export directly in the browser.
const katexPlugin: any = "default" in markdownItKatex ? markdownItKatex.default : markdownItKatex

// Map heading level to HTML tag
const HEADING_TAG = {
  1: "h3", // H1 -> H3
  2: "h4", // H2 -> H4
  3: "h5", // H3 -> H5
  4: "h6", // H4 -> H6
  5: "h6", // H5 -> H6
  6: "h6", // H6 -> H6
}

// Map heading level to subheading typography class
const HEADING_TYPOGRAPHY_CLASS = {
  1: "text-subheading-1",
  2: "text-subheading-2",
  3: "text-subheading-3",
  4: "text-subheading-3",
  5: "text-subheading-3",
  6: "text-subheading-3",
}

// markdown-it prevents unsafe links by default
// https://github.com/markdown-it/markdown-it/blob/master/docs/security.md
export const createMarkdownRenderer = () => {
  const md = new MarkdownIt({
    html: false,
    typographer: true,
  })
    .use(MarkdownItCollapsible)
    .use(katexPlugin)
    // @ts-ignore The type definitions for this plugin don't quite line up when using it correctly
    .use(markdownItFancyListPlugin)

  // Customize heading levels in the token stream before rendering
  md.core.ruler.after("block", "adjust-headings", (state) => {
    state.tokens.forEach((token) => {
      if (token.type === "heading_open" || token.type === "heading_close") {
        const markdownLevel = token.markup.length
        const htmlTag = HEADING_TAG[markdownLevel as keyof typeof HEADING_TAG]
        token.tag = htmlTag

        if (token.type === "heading_open") {
          const typographyClass = HEADING_TYPOGRAPHY_CLASS[markdownLevel as keyof typeof HEADING_TYPOGRAPHY_CLASS]
          token.attrSet("class", typographyClass)
        }
      }
    })
  })

  // Add target="_blank" and rel="noopener" to all links
  const defaultLinkRender = md.renderer.rules.link_open || md.renderer.renderToken
  md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
    const token = tokens[idx]
    token.attrSet("target", "_blank")
    token.attrSet("rel", "noopener")
    return defaultLinkRender.call(self, tokens, idx, options, env, self)
  }

  return md
}
