<template>
  <AppPage class="w-full">
    <section class="bg-background-sagelight">
      <AppPageHeader :backlink-route="{ name: 'wc-assets' }" backlink-text="Back to Dashboard" show-account-context>
        <span class="text-subheading-large-bold text-wrap pt-3.5">{{ assetGroup?.name }}</span>
        <div v-if="assetGroup?.createdTime" class="text-caption mt-1 text-hint">Group created: {{ formatDate(assetGroup?.createdTime) }}</div>
        <template v-if="assetGroup" #title-adjacent>
          <div class="relative -top-0.5 flex items-center gap-2">
            <span class="text-subheading-1">More actions</span>
            <WcMenu opener-size="medium">
              <WcMenuItem text="Edit asset group" icon="wc-carbon:edit" @click="showEditAssetGroupModal(assetGroup.id)" />
              <WcMenuItem text="Add Assets" icon="wc-carbon:add" @click="manageAssetGroupAssetsModal.openModal()" />
              <WcMenuItem text="Delete" icon="wc-carbon:trash-can" color="alert" @click="deleteAssetGroup" />
            </WcMenu>
          </div>
        </template>
      </AppPageHeader>
      <div class="wc-page-container grid grid-cols-2 pb-16 lg:grid-cols-4">
        <div class="col-span-1">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total Assets</h3>
          </div>
          <div>
            <div class="mb-1">
              <span class="text-featured-number pr-2" data-cy="total-active">{{ assetGroup?.deviceIds?.length }}</span>
            </div>
          </div>
        </div>
        <div class="col-span-1 lg:col-span-3">
          <div class="mb-4 flex items-center">
            <h3 class="text-overline-2 inline-block">Total EACs</h3>
          </div>
          <div>
            <div class="mb-1">
              <span class="text-featured-number pr-2" data-cy="total-retired"> {{ netEacs.quantity }} </span>
              <span> {{ netEacs.unit }} </span>
            </div>
            <div v-if="Number(netElectricityEacs.quantity) > 0 || Number(netCarbonEacs.quantity) > 0" class="text-body-3">
              {{ netElectricityEacs.quantity }} {{ netElectricityEacs.unit }} + {{ netCarbonEacs.quantity }} {{ netCarbonEacs.unit }}
            </div>
          </div>
        </div>
      </div>
      <WcTabs>
        <WcTab :is-active="selectedTab === Tab.GroupAssets" @click="selectedTab = Tab.GroupAssets">Group Assets</WcTab>
        <WcTab :is-active="selectedTab === Tab.EACProduction" @click="selectedTab = Tab.EACProduction">EAC Production</WcTab>
      </WcTabs>
    </section>
    <AppPageSection class="wc-page-container text-body-2 py-8">
      <div v-if="selectedTab === Tab.EACProduction">
        <AssetGroupLoadshapeView v-if="assetGroup" :asset-group="assetGroup" :summary="assetGroupSummary" />
      </div>
      <div v-if="selectedTab === Tab.GroupAssets">
        <div v-if="assetGroup?.deviceIds && assetGroup.deviceIds.length > 0">
          <div class="flex flex-wrap items-end justify-between gap-2">
            <div class="flex grow flex-wrap items-center gap-3">
              <WcDropdown
                v-model="filterAssetStatus"
                class="shrink-0 basis-40"
                name="filterAssetStatus"
                :options="statusOptions"
                inset-label="Status"
                size="small" />
              <WcCalendarRange
                v-model="filterAssetCreatedDateRange"
                class="shrink-0 basis-40"
                name="filterAssetCreatedDateRange"
                inset-label="Created date"
                size="small" />
              <WcInputText
                v-model="filterAssetLocationKeyword"
                icon="wc-carbon:search"
                class="shrink-0 basis-48"
                name="filterAssetLocationKeyword"
                inset-label="Filter by location..."
                size="small" />
              <WcCTA
                class="shrink-0 basis-52 pl-1"
                icon-position="right"
                icon="wc-carbon:close-outline"
                text="Clear Filters"
                size="small"
                @click="handleClearFilters" />
            </div>
            <WcViewModeButton v-model="viewMode" class="ml-auto" size="small" />
          </div>
          <div v-if="viewMode === ViewMode.table">
            <div class="mt-4 overflow-x-scroll">
              <AssetsTable
                :assets="assets"
                allow-multiple-selection
                :selected-row-ids="selectedAssetIds"
                show-eacs
                show-kind
                @row-clicked="handleRowClicked"
                @rows-selected="handleRowsSelected" />
            </div>
            <PaginationButtons class="my-3" :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
            <div
              v-if="selectedAssetIds.length > 0"
              class="mt-8 flex h-16 w-full flex-wrap items-center justify-between bg-darktone-lightbackground px-5 py-3">
              <div class="text-body-2 shrink-0">
                <div class="flex items-baseline gap-2">
                  <span class="text-subheading-large-bold">{{ selectedAssetIds.length }}</span>
                  <span class="text-subheading-1">assets selected</span>
                </div>
              </div>
              <div class="flex items-center gap-2">
                <WcButton
                  text="Cancel"
                  size="medium"
                  variant="secondary"
                  :is-disabled="selectedAssetIds.length === 0"
                  @click="handleClearSelection" />
                <WcButton text="Remove Assets" size="medium" :is-disabled="selectedAssetIds.length === 0" @click="removeSelectedAssets" />
              </div>
            </div>
          </div>
          <div v-if="viewMode === ViewMode.map && assetLocations?.length > 0">
            <div class="relative my-4 size-full h-[600px]">
              <WcMap :locations="assetLocations ?? []" class="mb-8" locations-are-precise>
                <template #popup="asset">
                  <AssetPopup :asset="asset" />
                </template>
              </WcMap>
              <PaginationButtons :page-info="pageInfo" :is-disabled="isLoading" @load-items="loadAssets" />
            </div>
          </div>
        </div>
        <EmptyState v-if="!isLoading && !hasError && assetGroup?.deviceIds?.length === 0" title="You haven't added any assets to this group yet">
          <div class="mb-16">Click below to get started</div>
          <WcButton text="Add Assets" @click="manageAssetGroupAssetsModal.openModal()" />
        </EmptyState>
        <EmptyState v-else-if="!isLoading && !hasError && assets.length === 0" title="No assets matched your filters">
          Adjust the filters to see results
        </EmptyState>
        <InlineLoading v-if="isLoading" />
        <InlineError v-if="hasError" error-message="There was a problem loading this asset group. Please try again." />
      </div>
    </AppPageSection>
    <EditAssetGroupModal ref="editAssetGroupModal" @asset-group-updated="handleAssetGroupUpdated" />
    <ManageAssetGroupAssetsModal
      ref="manageAssetGroupAssetsModal"
      :asset-group-id="assetGroupId"
      @asset-group-assets-updated="handleAssetGroupAssetsUpdated" />
  </AppPage>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useRouter } from "vue-router"
import { useToast } from "vue-toastification"
import { format } from "date-fns"
import type { AssetGroup, AssetStatus } from "@/models/asset"
import { ASSET_STATUS } from "@/models/asset"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import { AppPage, AppPageHeader, AppPageSection, WcTab, WcTabs } from "@/components/layout"
import { WcMenu, WcMenuItem } from "@/components/menu"
import EmptyState from "@/components/ui/EmptyState.vue"
import InlineError from "@/components/ui/InlineError.vue"
import InlineLoading from "@/components/ui/InlineLoading.vue"
import { useAssetService } from "@/services/service-container"
import AssetsTable from "./components/AssetsTable.vue"
import type { PageInfo } from "@/services/base-fetcher"
import { WcButton } from "@/components/button"
import { ViewMode, WcCalendarRange, WcDropdown, WcInputText, WcViewModeButton } from "@/components/input"
import type { SelectOption } from "@/components/input"
import PaginationButtons from "@/components/ui/PaginationButtons.vue"
import { WcCTA } from "@/components/button"
import { useConfirm } from "@/components/confirm/ConfirmContext"
import WcMap from "@/components/WcMap/WcMap.vue"
import { mapAssetsToLocations } from "@/modules/asset/asset.service"
import type { AssetGroupSummary, AssetWithSummary } from "@/modules/asset/asset.service"
import { netWhFromValues, netGco2eFromValues, netEacsFromValues } from "@/modules/registry/registry.utils"
import { debounce } from "@/utils/debounce"
import { formatDate } from "@/utils/formatDate"
import AssetGroupLoadshapeView from "./components/AssetGroupLoadshapeView.vue"
import AssetPopup from "./components/AssetPopup.vue"
import EditAssetGroupModal from "./components/EditAssetGroupModal.vue"
import ManageAssetGroupAssetsModal from "./components/ManageAssetGroupAssetsModal.vue"

enum Tab {
  GroupAssets = "groupAssets",
  EACProduction = "eacProduction",
}

const router = useRouter()
const assetService = useAssetService()
const { confirm } = useConfirm()

const props = defineProps<{ assetGroupId: number }>()

const editAssetGroupModal = ref()
const manageAssetGroupAssetsModal = ref()
const assetGroup = ref<AssetGroup | undefined>()
const assetGroupSummary = ref<AssetGroupSummary | undefined>()
const assets = ref<AssetWithSummary[]>([])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(true)
const pageInfo = ref<PageInfo>()
const selectedTab = ref<Tab>(Tab.GroupAssets)
const viewMode = ref<ViewMode>(ViewMode.table)
const filterAssetStatus = ref<SelectOption<AssetStatus> | null>(null)
const filterAssetLocationKeyword = ref<string | null | undefined>(null)
const filterAssetCreatedDateRange = ref<Date[] | null | undefined>(null)
const selectedAssetIds = ref<number[]>([])

const statusOptions = Object.entries(ASSET_STATUS).map(([status, { summary }]) => ({ label: summary, value: status }))

const toast = useToast()

onMounted(async () => {
  isLoading.value = true
  loadAssets()
})

const loadAssets = async (url?: string) => {
  try {
    const createdDateStart = (filterAssetCreatedDateRange.value ?? [])[0]
    const createdDateEnd = (filterAssetCreatedDateRange.value ?? [])[1]
    const filters = {
      summaryDatetimeStart: "1970-01-01",
      summaryDatetimeEnd: "9999-01-01",
      ...(filterAssetStatus.value && { status: filterAssetStatus.value.value }),
      ...(filterAssetLocationKeyword.value && { location: filterAssetLocationKeyword.value }),
      ...(createdDateStart && { createdDateStart: format(createdDateStart, "yyyy-MM-dd") }),
      ...(createdDateEnd && { createdDateEnd: format(createdDateEnd, "yyyy-MM-dd") }),
    }
    assetGroup.value = await assetService.getAssetGroup(props.assetGroupId)
    assetGroupSummary.value = await assetService.getAssetGroupSummary(props.assetGroupId)

    const results = await assetService.listAssets({ groupId: props.assetGroupId, per_page: 10, url, ...filters })
    assets.value = results.data
    pageInfo.value = results.pageInfo
  } catch (error: any) {
    if (error?.message === "Device group not found") {
      router.push({ name: "wc-404" })
      return
    }

    hasError.value = true
    console.error("There was an error loading this asset group", error)
  }
  isLoading.value = false
}

watch([filterAssetStatus], () => {
  loadAssets()
})

// Debounced text input
watch(
  [filterAssetCreatedDateRange, filterAssetLocationKeyword],
  debounce(() => {
    loadAssets()
  })
)

const assetLocations = computed(() => mapAssetsToLocations(assets.value))

const eacSummaryEntries = computed(() => {
  if (!assetGroupSummary.value?.eacs.sum && !assetGroupSummary.value?.preliminaryEacs.sum) {
    return []
  }
  return assetGroupSummary.value.eacs.sum
})

const netEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "0", unit: "" }
  const netEacs = netEacsFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netEacs, EacMeasurementParameter.UnlabeledLong)
})

const netElectricityEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netElectricity = netWhFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netElectricity, EacMeasurementParameter.Electricity)
})

const netCarbonEacs = computed(() => {
  if (eacSummaryEntries.value.length === 0) return { quantity: "", unit: "" }
  const netCarbon = netGco2eFromValues(eacSummaryEntries.value)
  return getFormattedEacQuantity(netCarbon, EacMeasurementParameter.GhgEmissions)
})

const handleRowClicked = (row: { id: number }, event: MouseEvent | KeyboardEvent) => {
  const path = `/assets/${row.id}`
  if (event.ctrlKey || event.metaKey || event.shiftKey) {
    window.open(path, "_blank")
  } else {
    router.push(path)
  }
}

const handleClearFilters = () => {
  filterAssetStatus.value = null
  filterAssetLocationKeyword.value = null
  filterAssetCreatedDateRange.value = null
  loadAssets()
}

const showEditAssetGroupModal = (assetGroupId: number) => {
  editAssetGroupModal.value.openModal(assetGroupId)
}

const handleAssetGroupUpdated = (addedGroup: AssetGroup | null) => {
  if (addedGroup) {
    assetGroup.value = addedGroup
  } else {
    router.push({ name: "wc-assets" })
  }
}
const handleAssetGroupAssetsUpdated = () => {
  loadAssets()
}

const handleRowsSelected = (selectedRowIDs: number[]) => {
  selectedAssetIds.value = selectedRowIDs
}

const handleClearSelection = () => {
  selectedAssetIds.value = []
}

const removeSelectedAssets = async () => {
  if (!assetGroup.value || selectedAssetIds.value.length === 0) {
    return
  }
  if (
    await confirm({
      title: "Remove Assets",
      message: "Are you sure you want to remove assets from this group? This action cannot be undone.",
      confirmText: "Remove Assets",
      icon: "wc-carbon:warning-alt",
    })
  ) {
    try {
      await assetService.removeAssetsFromAssetGroup(assetGroup.value.id, selectedAssetIds.value)
      toast.success(`${selectedAssetIds.value.length} ${selectedAssetIds.value.length === 1 ? "asset" : "assets"} removed from the group`)
      selectedAssetIds.value = []
      isLoading.value = true
      loadAssets()
    } catch (error) {
      console.error(error)
      toast.error("There was a problem removing assets from the group. Please try again.")
    }
  }
}

const deleteAssetGroup = async () => {
  if (
    assetGroup.value &&
    (await confirm({
      title: `Delete ${assetGroup.value.name}`,
      message: `Are you sure you want to delete this asset group? When a group is deleted, the assets are moved to the "Ungrouped Assets" list. This action cannot be undone.`,
      confirmText: "Delete Asset Group",
    }))
  ) {
    try {
      await assetService.deleteAssetGroup(assetGroup.value.id)
      router.replace({ name: "wc-assets" })
      toast.success(`Asset group ${assetGroup.value.name} deleted`)
    } catch (error) {
      toast.error("There was a problem deleting the asset group. Please try again.")
      throw error
    }
  }
}
</script>
