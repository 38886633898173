<template>
  <div class="grid grid-cols-2 gap-4 md:grid-cols-5">
    <div class="col-span-1">
      <h3 class="text-overline-2 mb-4 inline-block">Total Assets</h3>
      <div v-if="store.numAssets != null" data-cy="total-assets">
        <span class="text-featured-number pr-2">
          {{ store.numAssets }}
        </span>
        <span class="text-body-1">assets</span>
      </div>
    </div>
    <div class="col-span-1 md:col-span-2">
      <div class="mb-4">
        <h3 class="text-overline-2 inline-block">Total EACs</h3>
        <ToolTip class="ph-no-capture top-0.5 inline-block" panel-class="bg-white" button-class="!size-[11px] ml-2 !text-hint !border-hint">
          The total quantity of EACs associated with your account, including both active and retired EACs.
        </ToolTip>
      </div>
      <div data-cy="total-eacs">
        <div class="mb-3">
          <span class="text-featured-number pr-2">
            {{ eacCount.quantity }}
          </span>
          <span class="text-body-1">
            {{ eacCount.unit }}
          </span>
        </div>
        <div v-if="Number(netElectricity.quantity.replace(/,/g, '')) > 0 || Number(netEmissions.quantity.replace(/,/g, '')) > 0" class="text-body-3">
          {{ netElectricity.quantity }} {{ netElectricity.unit }} + {{ netEmissions.quantity }} {{ netEmissions.unit }}
        </div>
      </div>
    </div>
    <div class="col-span-1 md:col-span-2">
      <h3 class="text-overline-2 mb-4 inline-block">Most Recent Activity</h3>
      <div class="mb-3" data-cy="most-recent-activity">
        <span v-if="mostRecentTransaction?.Amount?.quantity" class="text-featured-number pr-2">
          {{ Math.abs(parseFloat(mostRecentTransaction.Amount.quantity.toString())) }}
        </span>
        <span class="text-body-1"> {{ mostRecentTransaction?.Summary ?? "None" }} </span>
      </div>
      <div v-if="mostRecentTransaction?.createdTime" class="text-body-3">
        {{ formatDateAndTime(mostRecentTransaction.createdTime) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import ToolTip from "@/components/ui/ToolTip.vue"
import { formatDateAndTime } from "@/utils/formatDateAndTime"
import { EacMeasurementParameter, getFormattedEacQuantity } from "@/models/order"
import { useDashboardMetricsStore } from "./dashboard.state"

const store = useDashboardMetricsStore()

const netElectricityEacs = computed(() => {
  if (!store.accountBalanceSummary) return null
  const numActive =
    (store.accountBalanceSummary.active.wh_electricity_supplied ?? 0) - (store.accountBalanceSummary.active.wh_electricity_consumed ?? 0)
  const numRetired =
    (store.accountBalanceSummary.retirement.wh_electricity_supplied ?? 0) - (store.accountBalanceSummary.retirement.wh_electricity_consumed ?? 0)
  return numActive + numRetired
})

const netEmissionsEacs = computed(() => {
  if (!store.accountBalanceSummary) return null
  const numActive = (store.accountBalanceSummary.active.g_co2e_avoided ?? 0) - (store.accountBalanceSummary.active.g_co2e_emitted ?? 0)
  const numRetired = (store.accountBalanceSummary.retirement.g_co2e_avoided ?? 0) - (store.accountBalanceSummary.retirement.g_co2e_emitted ?? 0)
  return numActive + numRetired
})

const eacCount = computed(() => {
  const counts = Object.values(store.accountBalanceSummary ?? {}).flatMap((subaccountSummary) => Object.values(subaccountSummary))
  if (counts.length === 0) return { quantity: "", unit: "" }
  const rawEacCount = counts.reduce((sum, c) => sum + c, 0)
  return getFormattedEacQuantity(rawEacCount, EacMeasurementParameter.UnlabeledLong)
})

const netElectricity = computed(() => {
  if (netElectricityEacs.value === null) return { quantity: "", unit: "" }
  return getFormattedEacQuantity(netElectricityEacs.value, EacMeasurementParameter.Electricity)
})

const netEmissions = computed(() => {
  if (netEmissionsEacs.value === null) return { quantity: "", unit: "" }
  return getFormattedEacQuantity(netEmissionsEacs.value, EacMeasurementParameter.GhgEmissions)
})

const mostRecentTransaction = computed(() => {
  if (!store.transactions?.length) return null
  return store.transactions[0]
})
</script>
