import { parseUTCTimestamp } from "@/utils/parseUTCTimestamp"

export function getStartOfQuarter(date: Date) {
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3, 1)
}

export function getStartOfFollowingQuarter(date: Date) {
  return new Date(date.getFullYear(), Math.floor(date.getMonth() / 3) * 3 + 3, 1)
}

export interface Quarter {
  description: string
  dateRange: { startDate: Date; endDate: Date }
}

export const constructAvailableEacQuarters = (dateRange: { lower: string; upper: string } | null) => {
  let date0, date1
  if (dateRange !== null) {
    date0 = getStartOfQuarter(parseUTCTimestamp(dateRange.lower))
    date1 = getStartOfFollowingQuarter(parseUTCTimestamp(dateRange.upper))
  } else {
    // the backend told us the user has no EACs at all. show the current quarter
    const now = new Date()
    date0 = getStartOfQuarter(now)
    date1 = getStartOfFollowingQuarter(now)
  }

  const quarters = []
  while (date0 < date1) {
    const startDate = date0
    const endDate = new Date(date0.getFullYear(), date0.getMonth() + 3, 1)
    const quarter: Quarter = {
      description: `${date0.getFullYear()} Q${Math.floor(date0.getMonth() / 3) + 1}`,
      dateRange: {
        startDate,
        endDate,
      },
    }
    quarters.push(quarter)
    date0 = quarter.dateRange.endDate
  }
  return quarters
}
