<template>
  <div v-if="!!portfolio" class="min-h-[600px] rounded-md bg-white p-3 shadow-md">
    <div class="flex w-full flex-row justify-between">
      <div>
        <h1 class="text-heading-4 mb-4 max-w-xl break-words">{{ portfolio.name }}</h1>

        <table>
          <tr>
            <th scope="row">ID</th>
            <td class="font-mono">{{ portfolio.id }}</td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td>{{ portfolio.status }}</td>
          </tr>
          <tr>
            <th scope="row">Total EACs</th>
            <td>{{ getFormattedEacQuantity(portfolio.quantityTotal, PORTFOLIO_MEASUREMENT_PARAMETERS[portfolio.units]) }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="mt-2 flex gap-2">
      <WcButton
        text="Delete Portfolio"
        color="alert"
        icon="wc-carbon:delete"
        size="small"
        :is-disabled="adminPortfoliosStore.isSaving"
        @click="deletePortfolio()" />
    </div>

    <nav class="my-4 flex flex-row gap-2 rounded-md bg-neutral-20 p-1">
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-allocations' }">Allocations</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-assets' }">Assets</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-suppliers' }">Suppliers</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-observers' }">Observers</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-stakeholders' }">Stakeholders</router-link>
      <router-link class="tab" :to="{ name: 'wc-admin-portfolio-config' }">Configuration</router-link>
    </nav>

    <router-view></router-view>
  </div>
  <div v-else>
    <p>Portfolio not found.</p>
  </div>
</template>

<script setup lang="ts">
import { computed, watchEffect } from "vue"
import { WcButton } from "@/components/button"
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { getFormattedEacQuantity, PORTFOLIO_MEASUREMENT_PARAMETERS } from "@/models/order"
import { useRouter } from "vue-router"

const adminPortfoliosStore = useAdminPortfoliosStore()
const router = useRouter()

const props = defineProps<{
  portfolioId: string
}>()

watchEffect(async () => {
  // Force a fetch from the backend when viewing an individual portfolio,
  // to ensure we have the latest data in case someone else is editing
  // simultaneously.
  if (props.portfolioId.length > 0) {
    try {
      await adminPortfoliosStore.fetchPortfolio(props.portfolioId)
    } catch {
      // ignore
    }
  }
})

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const deletePortfolio = async () => {
  if (confirm(`Delete portfolio '${portfolio.value.name}'?`)) {
    await adminPortfoliosStore.deletePortfolio(portfolio.value)
    router.push({ name: "wc-admin-portfolios" })
  }
}
</script>

<style scoped lang="scss">
table th {
  @apply text-left font-normal pr-8;
}

.tab {
  @apply px-4 py-2 rounded-md;
}

.router-link-active {
  @apply bg-neutral-30;
}
</style>
