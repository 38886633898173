<template>
  <WcMultiselect
    v-if="options.length > 0"
    v-model="model"
    :name="name"
    :options="options"
    header="Supplier"
    all-selected-label="All suppliers"
    scroll-height="400px">
  </WcMultiselect>
</template>

<script setup lang="ts">
import { defineModel, computed } from "vue"
import { WcMultiselect } from "@/components/input"
import { useDashboardMetricsStore } from "@/modules/dashboard/dashboard.state"

const model = defineModel<number[] | null>()
defineProps<{ name: string }>()

const dashboardMetricsStore = useDashboardMetricsStore()

const options = computed(() =>
  dashboardMetricsStore.suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.id,
  }))
)
</script>
