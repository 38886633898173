import { Coordinates, MinimalUser } from "./models"
import colors from "../colors"

export enum AssetKind {
  consumption = "consumption",
  demand_response = "demand_response",
  electrification_direct = "electrification_direct",
  electrification_nrel_resstock_deemed = "electrification_nrel_resstock_deemed",
  energy_efficiency_eemetered = "energy_efficiency_eemetered",
  solar = "solar",
  storage = "storage",
  vehicle_charging = "vehicle_charging",
}

export enum AssetCategory {
  demand_response = "demand_response",
  electrification = "electrification",
  energy_efficiency = "energy_efficiency",
  solar = "solar",
  storage = "storage",
  vehicle_charging = "vehicle_charging",
}

export enum AssetStatus {
  draft = "draft",
  ready = "ready",
  archived = "archived",
}

export enum AssetAuditStatus {
  pending = "pending",
  running = "running",
  failed = "failed",
  passed = "passed",
}

export const ASSET_KIND: Record<AssetKind, string> = {
  consumption: "Consumption",
  demand_response: "Demand Response",
  electrification_direct: "Electrification (Direct)",
  electrification_nrel_resstock_deemed: "Electrification (NREL ResStock Deemed)",
  energy_efficiency_eemetered: "Energy Efficiency (EEMeter)",
  solar: "Solar Energy",
  storage: "Storage",
  vehicle_charging: "Vehicle Charging",
}

export const ASSET_CATEGORY: Record<AssetCategory, string> = {
  demand_response: "Demand Response",
  electrification: "Electrification",
  energy_efficiency: "Energy Efficiency",
  solar: "Solar Energy",
  storage: "Storage",
  vehicle_charging: "Vehicle Charging",
}

export const ASSET_AUDIT_STATUS: Record<AssetAuditStatus, { color?: string; strokeColor?: string; summary: string; icon?: string }> = {
  [AssetAuditStatus.pending]: {
    color: colors.sagetone.DEFAULT,
    summary: "Pending",
    icon: "wc-carbon:license-draft",
  },
  [AssetAuditStatus.running]: {
    color: colors.sagetone.DEFAULT,
    summary: "Running",
    icon: "wc-carbon:in-progress",
  },
  [AssetAuditStatus.failed]: {
    color: colors.highlight.DEFAULT,
    strokeColor: colors.blue["40"],
    summary: "Failed",
  },
  [AssetAuditStatus.passed]: {
    color: colors.cornyellow.DEFAULT,
    summary: "Passed",
  },
}

export const ASSET_STATUS: Record<AssetStatus, { color?: string; strokeColor?: string; summary: string; icon?: string }> = {
  [AssetStatus.draft]: {
    color: colors.sagetone.DEFAULT,
    summary: "Draft",
    icon: "wc-carbon:license-draft",
  },
  [AssetStatus.ready]: {
    color: colors.highlight.DEFAULT,
    strokeColor: colors.blue["40"],
    summary: "Ready",
  },
  [AssetStatus.archived]: {
    color: colors.cornyellow.DEFAULT,
    summary: "Archived",
  },
}

export type AssetContactInfo = {
  name?: string
  email?: string
  phone?: string
  url?: string
  street1?: string
  street2?: string
  city?: string
  stateProvince?: string
  postalCode?: string
  country?: string
}

export class Asset {
  id!: number
  accountId!: number
  kind!: AssetKind
  createdTime!: string
  updatedTime!: string
  storyId?: number
  group?: AssetGroup

  // User-supplied values
  name?: string
  customId?: string
  address!: string
  provider!: string
  loadZone?: string
  utility?: string
  deviceOwner?: AssetContactInfo
  eacOwner?: AssetContactInfo
  methodologyUrl?: string

  // Auto-populated based on address
  city!: string
  state!: string
  zipCode!: string
  coordinates!: Coordinates
  climateZoneAshrae2004!: string
  cambiumRegion!: string
  nhgisCountyId!: string
  timezone!: string

  // Admin review process
  auditStatus!: AssetAuditStatus
  status!: AssetStatus
  reviewNotes?: string
  reviewNotesInternal?: string

  // Pricing
  price?: AssetPrice
}

export type PublicAsset = Pick<Asset, "coordinates" | "id" | "kind" | "state" | "utility" | "storyId"> & {
  category: AssetCategory
}

export enum AssetPricingFunctionType {
  per_mwh = "per_mwh",
  per_tco2 = "per_tco2",
}

export const ASSET_PRICING_FUNCTION_TYPE: Record<AssetPricingFunctionType, string> = {
  per_mwh: "MWh",
  per_tco2: "tCO2",
}

export type AssetPrice = {
  pricePerUnitPenniesUsd: number
  priceFunction: AssetPricingFunctionType
}

export enum MeteredSource {
  utility_api = "utility_api",
}

export const METERED_SOURCE: Record<MeteredSource, string> = {
  utility_api: "UtilityAPI",
}

export class DemandResponseAsset extends Asset {
  meterId!: string
  servicePointNumber?: string

  loadServingEntity?: string
  billCustomerAgreementNumber?: string
  billServiceAccountNumber?: string
}

export enum RecRegistry {
  gats = "gats",
  mrets = "mrets",
  nepool = "nepool",
}

export const REC_REGISTRY: Record<RecRegistry, string> = {
  gats: "GATS",
  mrets: "MRETS",
  nepool: "NEPOOL",
}

export class SolarAsset extends Asset {
  meterId!: string
  nameplateCapacityKw!: number
  commencedOperationDate?: string
  estimatedAnnualProductionMwh?: number
  eiaPlantId?: string

  externalRegistryName?: RecRegistry
  externalRegistryDeviceId?: string
}

export enum HeatingBackup {
  none = "none",
  electric_resistance = "electric_resistance",
  existing_fuel = "existing_fuel",
}

export const HEATING_BACKUP: Record<HeatingBackup, string> = {
  none: "None",
  electric_resistance: "Electric Resistance",
  existing_fuel: "Existing Fuel",
}

export enum EnergyEfficiencyImprovements {
  none = "none",
  basic = "basic",
  enhanced = "enhanced",
}

export const ENERGY_EFFICIENCY_IMPROVEMENTS: Record<EnergyEfficiencyImprovements, string> = {
  none: "None",
  basic: "Basic",
  enhanced: "Enhanced",
}

export enum HeatingFuel {
  natural_gas = "natural_gas",
  fuel_oil = "fuel_oil",
  wood = "wood",
}

export const HEATING_FUEL: Record<HeatingFuel, string> = {
  natural_gas: "Natural Gas",
  fuel_oil: "Fuel Oil",
  wood: "Wood",
}

export enum InsulationStatus {
  insulated = "insulated",
  uninsulated = "uninsulated",
}

export const INSULATION_STATUS: Record<InsulationStatus, string> = {
  insulated: "Insulated",
  uninsulated: "Uninsulated",
}

export class ElectrificationAsset extends Asset {
  areaSqft!: number

  effectiveUsefulLifeYears?: number
  heatingBackupSystem?: HeatingBackup
  waterHeaterUef?: number
  heatPumpHspf?: number
  dryerCef?: number
  coldClimate?: boolean
  energyEfficiencyImprovements?: EnergyEfficiencyImprovements

  replacesFurnace!: boolean
  replacesWaterHeater!: boolean
  replacesOven!: boolean
  replacesDryer!: boolean
  replacesPoolHeater!: boolean

  previousFuel?: HeatingFuel
  previousFurnaceAfue?: number
  previousWaterHeaterUef?: number
  previousInsulationStatus?: InsulationStatus

  likelyHeatingThermostatSetpointF?: number
  likelyHeatingThermostatSetpointOffsetF?: number
  infiltrationRateAch50?: number
  yearBuilt?: number
}

export enum InterventionType {
  hvac_or_refrigeration = "hvac_or_refrigeration",
  ems_or_behavioral = "ems_or_behavioral",
  envelope = "envelope",
  lighting = "lighting",
  plug_loads = "plug_loads",
}

export const INTERVENTION_TYPE: Record<InterventionType, string> = {
  hvac_or_refrigeration: "HVAC/Refrigeration",
  ems_or_behavioral: "EMS/Behavioral",
  envelope: "Envelope",
  lighting: "Lighting",
  plug_loads: "Plug Loads",
}

export enum InterventionMeasureUpgrade {
  hp_rtu_electric_backup = "hp_rtu_electric_backup",
  hp_rtu_original_backup = "hp_rtu_original_backup",
  vrf_with_doas = "vrf_with_doas",
  doas_hp_minisplits = "doas_hp_minisplits",
  hp_boiler_electric_backup = "hp_boiler_electric_backup",
  hp_boiler_gas_backup = "hp_boiler_gas_backup",
  economizer = "economizer",
  dcv = "dcv",
  energy_recovery = "energy_recovery",
  led_lighting = "led_lighting",
  wall_insulation = "wall_insulation",
  roof_insulation = "roof_insulation",
  secondary_windows = "secondary_windows",
  window_film = "window_film",
  new_windows = "new_windows",
}

export const INTERVENTION_MEASURE_UPGRADE: Record<InterventionMeasureUpgrade, string> = {
  hp_rtu_electric_backup: "Heat Pump Rooftop Unit, Electric Backup",
  hp_rtu_original_backup: "Heat Pump Rooftop Unit, Original Backup",
  vrf_with_doas: "Variable Refrigerant Flow with Heat Recovery and Dedicated Outdoor Air System",
  doas_hp_minisplits: "Dedicated Outdoor Air System Mini Split Heat Pump",
  hp_boiler_electric_backup: "HP Boiler, Electric Backup",
  hp_boiler_gas_backup: "HP Boiler, Gas Backup",
  economizer: "Economizer",
  dcv: "Demand Control Ventilation",
  energy_recovery: "Energy Recovery",
  led_lighting: "LED Lighting",
  wall_insulation: "Wall Insulation",
  roof_insulation: "Roof Insulation",
  secondary_windows: "Secondary Windows",
  window_film: "Window Film",
  new_windows: "New Windows",
}

export enum BuildingType {
  single_family_detached = "single_family_detached",
  single_family_attached = "single_family_attached",
  multi_family_2_4 = "multi_family_2_4",
  multi_family_5 = "multi_family_5",
  mobile_home = "mobile_home",
  small_office = "small_office",
  medium_office = "medium_office",
  large_office = "large_office",
  small_hotel = "small_hotel",
  large_hotel = "large_hotel",
  retail_stripmall = "retail_stripmall",
  retail_standalone = "retail_standalone",
  quick_service_restaurant = "quick_service_restaurant",
  full_service_restaurant = "full_service_restaurant",
  warehouse = "warehouse",
  outpatient = "outpatient",
  hospital = "hospital",
  primary_school = "primary_school",
  secondary_school = "secondary_school",
}

export const BUILDING_TYPE: Record<BuildingType, string> = {
  single_family_detached: "Single-Family Detached",
  single_family_attached: "Single-Family Attached",
  multi_family_2_4: "Multi-Family 2-4 Units",
  multi_family_5: "Multi-Family 5+ Units",
  mobile_home: "Mobile Home",
  small_office: "Small Office",
  medium_office: "Medium Office",
  large_office: "Large Office",
  small_hotel: "Small Hotel",
  large_hotel: "Large Hotel",
  retail_stripmall: "Retail Strip Mall",
  retail_standalone: "Retail Standalone",
  quick_service_restaurant: "Quick Service Restaurant",
  full_service_restaurant: "Full Service Restaurant",
  warehouse: "Warehouse",
  outpatient: "Outpatient",
  hospital: "Hospital",
  primary_school: "Primary School",
  secondary_school: "Secondary School",
}

export class EnergyEfficiencyAsset extends Asset {
  interventionType?: InterventionType
  interventionMeasureUpgrade!: InterventionMeasureUpgrade
  buildingType!: BuildingType
  livingSpaceSqft?: number
}

export interface Implementer {
  id: string
  name: string
}

export type AssetsSummary = {
  countsByStatus: Record<AssetStatus, number>
  assetCount: number
}

export function getAssetDisplayName(asset?: Asset) {
  return asset?.name || `Asset ${asset?.id}`
}

export enum AssetDocumentType {
  invoice = "invoice",
  permit = "permit",
  rebate = "rebate",
  savings_data = "savings_data",
  timeseries_data = "timeseries_data",
  other = "other",
  lighting_inventory = "lighting_inventory",
}

export const ASSET_DOCUMENT_TYPE: Record<AssetDocumentType, string> = {
  invoice: "Invoice",
  permit: "Permit",
  rebate: "Rebate",
  savings_data: "Savings Data",
  timeseries_data: "Time series data",
  other: "Other",
  lighting_inventory: "Lighting Inventory",
}

export type AssetDocument = {
  createdTime: string
  date: string
  description: string
  downloadUrl: string
  id: number
  name: string
  type: AssetDocumentType
}

export const US_STATE_NAMES_BY_ABBREVIATION = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
}

export const US_STATE_ABBREVIATIONS = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
}

export const getUsStateNameFromAbbreviation = (state: keyof typeof US_STATE_NAMES_BY_ABBREVIATION): string => {
  return US_STATE_NAMES_BY_ABBREVIATION[state] || ""
}

export const getUsStateAbbreviation = (state: keyof typeof US_STATE_ABBREVIATIONS): string => {
  return US_STATE_ABBREVIATIONS[state] || ""
}

export type AssetSubmissionRow = {
  line: number
  errors: string[]
  warnings: string[]
} & Record<string, any>

export interface AssetGroupCreate {
  name: string
  description?: string
  customId?: string
}

export interface AssetGroup extends AssetGroupCreate {
  id: number
  accountId: number
  createdTime: string
  updatedTime: string
  deviceIds: number[]
}

export interface AssetVersion {
  id: number
  createdTime: string
  user: MinimalUser
  device: Asset
}

export interface AssetJob {
  deviceId: number
  kind: string
  status: string
  timestamp: string
}
