import { AccountName, Coordinates } from "./models"
import { Story } from "./story"

export enum PortfolioStatusType {
  setup = "setup",
  funding = "funding",
  deploying = "deploying",
  complete = "complete",
  cancelled = "cancelled",
}

export enum EacMeasurementParameter {
  EACs = "eacs",
  Electricity = "electricity",
  CarbonDioxide = "carbon_dioxide",
  GhgEmissions = "ghg_emissions",
  UnlabeledLong = "unlabeled_long",
  UnlabeledShort = "unlabeled_short",
}

export interface AllocationTotal {
  allocatedEacs: number
  allocatedNetPortfolioUnits: number
  allocatedPositivePortfolioUnits: number
  allocatedNegativePortfolioUnits: number
  allocatedNetGco2e: number
  updatedTime: string
}

export interface PortfolioAllocation {
  account: { id: number; name: string }
  quantity: number | null
  allocation: AllocationTotal
}

export interface PortfolioRelationship {
  accountId: number
  quantity: number
  name: string
  createdTime: string
  updatedTime: string
}

export type PortfolioSupplierRelationship = PortfolioRelationship // identical (for now!)

export interface PortfolioStakeholderRelationship extends PortfolioRelationship {
  paidPenniesUsd: number | null
}

export interface PortfolioObserverRelationship extends PortfolioRelationship {
  referrer: AccountName
}

export interface PortfolioRelationships {
  supplier: PortfolioSupplierRelationship
  observer: PortfolioObserverRelationship
  stakeholder: PortfolioStakeholderRelationship
}

export interface DeviceSummary {
  id: number
  coordinates: Coordinates
  location: string
  city: string
  state: string
  kind: string
  story: Story
  allocation: AllocationTotal
  relationships: PortfolioRelationships
}

enum PortfolioUnits {
  wh_electricity = "wh_electricity",
  g_co2e = "g_co2e",
}

export interface Portfolio {
  id: string
  name: string
  units: PortfolioUnits
  quantityTotal: number
  status: PortfolioStatusType
  createdTime: string
  updatedTime: string
}

export interface PortfolioWithSummary extends Portfolio {
  relationships: PortfolioRelationships
  allocations: AllocationTotal
}

export interface PortfolioWithDetails extends Portfolio {
  relationships: PortfolioRelationships
  devices: DeviceSummary[]
}

export const PORTFOLIO_MEASUREMENT_PARAMETERS: { [key in PortfolioUnits]: EacMeasurementParameter } = {
  wh_electricity: EacMeasurementParameter.Electricity,
  g_co2e: EacMeasurementParameter.GhgEmissions,
}

const units: { [key in EacMeasurementParameter]: string[] } = {
  eacs: ["EACs", "thousand EACs", "million EACs", "billion EACs"],
  electricity: ["Wh", "kWh", "MWh", "GWh"],
  carbon_dioxide: ["gCO2", "kgCO2", "tCO2", "ktCO2"],
  ghg_emissions: ["gCO2e", "kgCO2e", "tCO2e", "ktCO2e"],
  unlabeled_long: ["", "thousand", "million", "billion"],
  unlabeled_short: ["", "k", "m", "b"],
}

export enum ScaleFactor {
  Base = 0, // Original unit
  Kilo = 1, // × 10³
  Mega = 2, // × 10⁶
  Giga = 3, // × 10⁹
}

// also accepts a parameter of type number, representing the number of decimal places. defaults to 2
export const getFormattedEacQuantity = (
  quantity: number,
  parameter: EacMeasurementParameter,
  decimalPlaces = 2,
  signDisplay: "auto" | "always" | "exceptZero" | "never" = "auto",
  maxScaleFactor: ScaleFactor = ScaleFactor.Mega
) => {
  let formattedQuantity: string, unit: string
  if (Math.abs(quantity) >= 1_000_000_000 && maxScaleFactor >= ScaleFactor.Giga) {
    formattedQuantity = (quantity / 1_000_000_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][ScaleFactor.Giga]
  } else if (Math.abs(quantity) >= 1_000_000 && maxScaleFactor >= ScaleFactor.Mega) {
    formattedQuantity = (quantity / 1_000_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][ScaleFactor.Mega]
  } else if (Math.abs(quantity) >= 1_000 && maxScaleFactor >= ScaleFactor.Kilo) {
    formattedQuantity = (quantity / 1_000).toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][ScaleFactor.Kilo]
  } else {
    formattedQuantity = quantity.toLocaleString(undefined, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
      signDisplay,
    })
    unit = units[parameter][ScaleFactor.Base]
  }
  return {
    quantity: formattedQuantity,
    unit,
    toString: () => `${formattedQuantity} ${unit}`,
  }
}

export const getFormattedEacQuantityForMillions = (
  quantity: number,
  parameter: EacMeasurementParameter,
  decimalPlaces = 2,
  signDisplay: "auto" | "always" | "exceptZero" | "never" = "auto"
) => {
  const formattedQuantity = (quantity / 1_000_000).toLocaleString(undefined, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
    signDisplay,
  })
  const unit = units[parameter][2]
  return {
    quantity: formattedQuantity,
    unit,
    toString: () => `${formattedQuantity} ${unit}`,
  }
}
