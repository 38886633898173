// import useFeature from "./useFeature"

// These represent feature flags defined in posthog. Flags should be
// short-lived, existing only during development of a larger feature, or as
// part of an A/B test with a concrete outcome.

/* e.g.
// Tracking removal in WATT-#### (Deadline: May 21)
// https://linear.app/wattcarbon/issue/WATT-####
export const useExampleFeature = () => useFeature("example-feature", { valueInDev: true })
*/

import useFeature from "./useFeature"

// This is a semi-long-lived feature flag that we'll use to allowlist time
// series uploads to WC internal users and a limited set of external users.
// Re-evaluate June 1, 2025.
export const useAllowUploadAssetTimeSeries = () => useFeature("allow-upload-asset-timeseries", { valueInDev: true })

// Tracking removal in WATT-2483 (Deadline: Feb 21)
// https://linear.app/wattcarbon/issue/WATT-2483/remove-public-transaction-modal-feature-flag-when-the-feature-is
export const usePublicTransactionModal = () => useFeature("public-transaction-modal", { valueInDev: true })
