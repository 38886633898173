<template>
  <Icon v-if="isLoading" icon="mdi:loading" class="mx-auto my-8 size-20 animate-spin" />

  <template v-else>
    <table class="w-full">
      <thead class="border-b border-sagetone">
        <tr>
          <th class="text-left">Observer</th>
          <th class="text-left">Referrer</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="divide-y divide-sagetone">
        <tr v-for="observer in observers" :key="observer.accountId">
          <td class="max-w-[20ch] truncate py-2 text-left underline">
            <router-link :to="{ name: 'wc-admin-account-portfolios', params: { accountId: observer.accountId } }">
              {{ observer.name }}
            </router-link>
          </td>
          <td class="py-2 text-left">
            <router-link :to="{ name: 'wc-admin-account-portfolios', params: { accountId: observer.referrer.id } }">
              {{ observer.referrer.name }}
            </router-link>
          </td>
          <td class="flex justify-end py-2 pr-2">
            <button class="text-error disabled:cursor-not-allowed disabled:text-sagetone" @click="removeObserver(observer)">
              <Icon icon="wc-carbon:close-outline" />
              Remove
            </button>
          </td>
        </tr>
        <tr v-if="observers.length == 0">
          <td colspan="3" class="py-2">No observers.</td>
        </tr>
      </tbody>
      <tfoot class="border-t border-sagetone">
        <tr>
          <td class="py-2">
            <button class="text-blue-70 disabled:cursor-not-allowed disabled:text-sagetone" @click="isAddObserverDialogOpen = true">
              <Icon icon="wc-carbon:add" />
              Add Observer
            </button>
          </td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  </template>

  <WcModal :is-open="isAddObserverDialogOpen" header="Attach Observer" @update:is-open="(isOpen) => (isAddObserverDialogOpen = isOpen)">
    <template #panel>
      <form ref="formRef" @submit.prevent="addObserver">
        <label>
          Observer
          <select name="observer" class="p-2" required>
            <option></option>
            <option v-for="a in unattachedAccounts" :key="a.id" :value="a.id">
              {{ a.name }}
            </option>
          </select>
        </label>

        <label>
          Referrer
          <select name="referrer" class="p-2" required>
            <option></option>
            <option v-for="s in suppliers" :key="s.accountId" :value="s.accountId">
              {{ s.name }}
            </option>
          </select>
        </label>
      </form>
    </template>
    <template #footer>
      <WcButton text="Cancel" variant="secondary" size="small" @click="isAddObserverDialogOpen = false" />
      <WcButton text="Attach" size="small" @click="formRef?.requestSubmit()" />
    </template>
  </WcModal>
</template>

<script setup lang="ts">
import { useAdminPortfoliosStore } from "@/modules/admin/adminPortfolios.state"
import { useAdminAccountsStore } from "@/modules/admin/adminAccounts.state"
import { computed, ref, onMounted } from "vue"
import { PortfolioSupplierRelationship, PortfolioObserverRelationship } from "@/models/order"
import { Icon } from "@iconify/vue"
import { WcButton } from "@/components/button"
import WcModal from "@/components/WcModal.vue"
import _ from "lodash"

const props = defineProps<{
  portfolioId: string
}>()

const adminPortfoliosStore = useAdminPortfoliosStore()
const adminAccountsStore = useAdminAccountsStore()

const formRef = ref<HTMLFormElement | null>(null)

const portfolio = computed(() => adminPortfoliosStore.getPortfolioById(props.portfolioId)!)

const isLoading = ref<boolean>(true)
const suppliers = ref<PortfolioSupplierRelationship[]>([])
const observers = ref<PortfolioObserverRelationship[]>([])
onMounted(async () => {
  isLoading.value = true
  try {
    ;[suppliers.value, observers.value] = await Promise.all([
      adminPortfoliosStore.listSuppliers(props.portfolioId),
      adminPortfoliosStore.listObservers(props.portfolioId),
    ])
  } finally {
    isLoading.value = false
  }
})

const isAddObserverDialogOpen = ref<boolean>(false)
const unattachedAccounts = computed(() => {
  const unattached = _.differenceWith(adminAccountsStore.accounts, observers.value, (a, o) => a.id == o.accountId)
  return _.sortBy(unattached, ["name"])
})

const addObserver = async (e: Event) => {
  const formEl = e.target! as HTMLFormElement
  const observer = adminAccountsStore.getAccountById(parseInt(formEl.observer.value))!
  const referrer = adminAccountsStore.getAccountById(parseInt(formEl.referrer.value))!

  const newObserver = await adminPortfoliosStore.addObserver(portfolio.value, observer, referrer)
  observers.value.push(newObserver)

  isAddObserverDialogOpen.value = false
  formEl.reset()
}

const removeObserver = async (observer: PortfolioObserverRelationship) => {
  if (confirm(`Remove ${observer.name} from this portfolio?`)) {
    await adminPortfoliosStore.removeObserver(portfolio.value, observer)

    const observerIndex = observers.value.indexOf(observer)
    if (observerIndex !== -1) {
      observers.value.splice(observerIndex, 1)
    }
  }
}
</script>

<style scoped lang="scss">
button {
  @apply flex gap-2 items-center;
}
</style>
