<template>
  <!-- Hour grid -->
  <div class="relative mb-1 overflow-x-scroll py-3">
    <div
      ref="hourGridField"
      class="hourgrid relative ml-8 mr-auto mt-12 h-[334px] select-none bg-carbon"
      :style="{ width: gridWidth }"
      @pointerdown="handleMouseDown"
      @pointermove="handleMouseMove"
      @pointerup="handleMouseUp">
      <Transition name="fade" mode="out-in">
        <div v-if="isLoading" class="absolute-centered-text">Loading EACs</div>
        <div v-else-if="totalAvailableQuantity === 0" class="absolute-centered-text">
          There are no EACs in this date range.<br />Select another date range above.
        </div>
      </Transition>
      <!-- Vertical Axis -->
      <div class="absolute -left-8 bg-white">
        <div v-for="hour in hours" :key="hour" class="hour-label h-[14px] w-8 whitespace-nowrap pr-1.5 text-right">
          {{ hour }}
        </div>
      </div>
      <!-- Horizontal Axis -->
      <div class="absolute bottom-full mb-1 w-full">
        <div class="-ml-px mb-1 mt-4 flex h-8">
          <!-- Flex basis, shrink and grow values ensure that the distance of axis ticks stays consistent -->
          <div v-for="date in dates" :key="date.toString()" class="relative size-[14px] shrink-0 grow-0 basis-[14px] text-center">
            <div v-if="date.getDate() === 1" class="text-caption absolute top-0 h-8 w-32 pl-[3px] pt-0 text-left leading-3">
              {{ format(date, "LLLL Y") }}
            </div>
            <div class="hour-label mt-6 h-3 w-full text-center leading-3">{{ date.getDate() }}</div>
          </div>
        </div>
      </div>
      <!-- Hourgrid Square -->
      <div class="relative">
        <div
          v-for="(balance, dateTime) in balances"
          :key="dateTime as string"
          class="absolute size-[12px] cursor-pointer"
          :class="getGridSquareClass(balance)"
          :style="{
            top: `${balance.hourIndex * gridPixelSize}px`,
            left: `${balance.dateIndex * gridPixelSize}px`,
          }"
          :data-datetime="dateTime"></div>
      </div>

      <div
        class="hourgrid-out-of-view absolute inset-y-0 right-0 bg-neutral-50"
        :style="{ left: `${dates.length * gridPixelSize}px` }"
        @pointerdown="(e) => e.stopPropagation()"
        @pointermove="(e) => e.stopPropagation()"
        @pointerup="(e) => e.stopPropagation()"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { format } from "date-fns"
import type { EacBalance, EacBalances, NullableEacRangeSelection } from "@/modules/accounts/eac.state"

const gridPixelSize = 14

const hourGridField = ref<HTMLElement | undefined>(undefined)

const hours = [
  "12 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
]

const props = defineProps<{
  balances: EacBalances | null
  isLoading: boolean
  startDate: Date
  endDate: Date
}>()

const selectedEacRange = ref<NullableEacRangeSelection>(null)

const totalAvailableQuantity = computed(() => {
  if (!props.balances) return 0
  return Object.values(props.balances).reduce((total, hour) => total + hour.active + hour.retirement, 0)
})

const dates = computed(() => {
  const currentDate = new Date(props.startDate)

  const result = []
  while (currentDate < props.endDate) {
    result.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return result
})

const gridWidth = computed(() => {
  const filledGridWidth = dates.value.length * gridPixelSize - 2
  return `${Math.max(filledGridWidth, 1288)}px`
})

const emit = defineEmits(["eac-range-selected"])

const getGridSquareClass = (eacDateTime: any) => {
  if (eacDateTime.retirement > 0) {
    return isDateTimeSelected(eacDateTime.dateTime) ? "bg-neonred-300" : "bg-neonred hover:bg-neonred-300"
  } else {
    return isDateTimeSelected(eacDateTime.dateTime) ? "bg-highlight-yellow" : "bg-sagetone-dark hover:bg-highlight-yellow"
  }
}

class Position {
  x: number
  y: number
  constructor(x: number, y: number) {
    this.x = x
    this.y = y
  }
}

let mouseDownPosition: Position | null = null
let mouseHoldPosition: Position | null = null

const handleMouseDown = (event: PointerEvent) => {
  if (hourGridField.value === undefined) return
  const relativePosition = getRelativePosition(event)
  if (!relativePosition) return
  mouseDownPosition = mouseHoldPosition = relativePosition
  updateSelectedRange()
  hourGridField.value.setPointerCapture(event.pointerId)
}

const handleMouseMove = (event: MouseEvent) => {
  if (mouseDownPosition === null) return
  const relativePosition = getRelativePosition(event)
  if (!relativePosition) return
  mouseHoldPosition = new Position(relativePosition.x, relativePosition.y)
  updateSelectedRange()
}

const handleMouseUp = (event: PointerEvent) => {
  mouseDownPosition = mouseHoldPosition = null
  hourGridField.value!.releasePointerCapture(event.pointerId)
}

const getRelativePosition = (event: MouseEvent): Position | null => {
  if (!hourGridField.value) return null
  const hourGridFieldRect = hourGridField.value.getBoundingClientRect()
  const relativeX = event.clientX - hourGridFieldRect.left
  const relativeY = event.clientY - hourGridFieldRect.top
  if (relativeX < 0 || relativeX > hourGridFieldRect.width || relativeY < 0 || relativeY > hourGridFieldRect.height) return null
  return new Position(relativeX, relativeY)
}

const getDragRange = () => {
  if (!mouseDownPosition || !mouseHoldPosition) return null
  const minX = Math.min(mouseDownPosition.x, mouseHoldPosition.x)
  const maxX = Math.max(mouseDownPosition.x, mouseHoldPosition.x)
  const minY = Math.min(mouseDownPosition.y, mouseHoldPosition.y)
  const maxY = Math.max(mouseDownPosition.y, mouseHoldPosition.y)
  const minDateIndex = Math.floor(minX / gridPixelSize)
  const maxDateIndex = Math.floor(maxX / gridPixelSize)
  const minHourIndex = Math.floor(minY / gridPixelSize)
  const maxHourIndex = Math.floor(maxY / gridPixelSize)
  return { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } as NullableEacRangeSelection
}

const updateSelectedRange = () => {
  const dragRange = getDragRange()
  selectedEacRange.value = dragRange
  emit("eac-range-selected", dragRange)
}

const isBalanceSelected = (balance: EacBalance) => {
  if (!selectedEacRange.value) return false
  const { minDateIndex, maxDateIndex, minHourIndex, maxHourIndex } = selectedEacRange.value
  return (
    minHourIndex <= balance.hourIndex && balance.hourIndex <= maxHourIndex && minDateIndex <= balance.dateIndex && balance.dateIndex <= maxDateIndex
  )
}

const isDateTimeSelected = (isoDateString: string) => {
  if (props.balances === null) return false
  const balance = props.balances[isoDateString]
  return isBalanceSelected(balance)
}

const resetSelection = () => {
  selectedEacRange.value = null
  emit("eac-range-selected", null)
}
defineExpose({ resetSelection })
</script>

<style lang="scss" scoped>
.absolute-centered-text {
  @apply absolute z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-center pointer-events-none;
}

.hourgrid {
  background-size: 14px 14px;
  // Draw a grid pattern with 12px cells and 2px border between cells (but not along the boundaries of the grid)
  background-image: linear-gradient(to right, transparent 12px, #1d2739 12px, #1d2739 14px, transparent 14px),
    linear-gradient(to bottom, transparent 12px, #1d2739 12px, #1d2739 14px, transparent 14px);
}
.hourgrid-out-of-view {
  background-size: 14px 14px;
  // Draw a grid pattern with 12px cells and 2px border between cells (but not along the boundaries of the grid)
  background-image: linear-gradient(to right, transparent 12px, #686f7c 12px, #686f7c 14px, transparent 14px),
    linear-gradient(to bottom, transparent 12px, #686f7c 12px, #686f7c 14px, transparent 14px);
}
.hour-label {
  @apply text-[9px] leading-[12px] text-hint;
}
</style>
