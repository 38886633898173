<template>
  <div>
    <div class="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      <router-link
        v-for="portfolio in visiblePortfolios"
        :key="portfolio.id"
        :to="{ name: 'wc-portfolio-tracker', params: { portfolioId: portfolio.id } }"
        class="flex flex-col rounded-lg border border-neutral-30 px-4 pb-4 pt-3">
        <h3 class="text-subheading-1 mb-5 min-w-0 break-words">{{ portfolio.name }} &mdash; {{ portfolio.id }}</h3>
        <div class="flex grow flex-col">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <div class="text-subheading-large-bold">
                {{ getFormattedEacQuantity(portfolio.allocations?.allocatedNetPortfolioUnits ?? 0, measurementParameter(portfolio)) }}
              </div>
              <div class="text-body-3">EACs allocated</div>
            </div>
            <div v-if="hasQuantityOrdered(portfolio)">
              <div class="text-subheading-large-bold">
                {{ getFormattedEacQuantity(computeQuantityOrdered(portfolio), measurementParameter(portfolio)) }}
              </div>
              <div class="text-body-3">EACs ordered</div>
            </div>
          </div>
          <WcProgressBarChart
            v-if="hasQuantityOrdered(portfolio)"
            class="mt-5"
            :percentage="computePercentAllocated(portfolio)"
            label="percentage fulfilled" />
        </div>
        <div class="text-caption mt-6 grid grow-0 grid-cols-2 gap-4 text-hint">
          <div class="capitalize">Status: {{ portfolio.status }}</div>
          <div v-if="computeLatestUpdateTime(portfolio)">Last updated: {{ formatDate(computeLatestUpdateTime(portfolio)) }}</div>
        </div>
      </router-link>
    </div>
    <div v-if="totalPages > 1" class="mt-5 flex justify-end gap-8">
      <WcCTA
        text="Previous"
        icon="wc-carbon:chevron-left"
        icon-position="left"
        :is-disabled="currentPage === 0"
        size="medium"
        weight="heavy"
        @click="currentPage--" />
      <WcCTA
        text="Next"
        icon="wc-carbon:chevron-right"
        icon-position="right"
        :is-disabled="currentPage >= totalPages - 1"
        size="medium"
        weight="heavy"
        @click="currentPage++" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue"
import { getFormattedEacQuantity, EacMeasurementParameter } from "@/models/order"
import { PortfolioWithSummary } from "@/models/order"
import { WcCTA } from "@/components/button"
import WcProgressBarChart from "@/components/visualizations/WcProgressBarChart.vue"
import { formatDate } from "@/utils/formatDate"

const ITEMS_PER_PAGE = 12

const props = defineProps<{ portfolios: PortfolioWithSummary[] }>()

const currentPage = ref(0)

const totalPages = computed(() => Math.ceil(props.portfolios.length / ITEMS_PER_PAGE))
const startIndex = computed(() => currentPage.value * ITEMS_PER_PAGE)
const endIndex = computed(() => startIndex.value + ITEMS_PER_PAGE)

const visiblePortfolios = computed(() => props.portfolios.slice(startIndex.value, endIndex.value))

const hasQuantityOrdered = (portfolio: PortfolioWithSummary) => portfolio.relationships?.stakeholder !== null

const computeQuantityOrdered = (portfolio: PortfolioWithSummary) => portfolio.relationships.stakeholder!.quantity

const measurementParameter = (portfolio: PortfolioWithSummary) => {
  return portfolio.units === "wh_electricity" ? EacMeasurementParameter.Electricity : EacMeasurementParameter.GhgEmissions
}

const computePercentAllocated = (portfolio: PortfolioWithSummary) => {
  const allocated = portfolio.allocations?.allocatedNetPortfolioUnits ?? 0
  const totalOrdered = computeQuantityOrdered(portfolio)
  if (allocated === 0 || totalOrdered === 0) {
    return 0
  }
  return (allocated / totalOrdered) * 100
}

const computeLatestUpdateTime = (portfolio: PortfolioWithSummary) => {
  const sortedUpdateTimes = [
    portfolio.updatedTime,
    portfolio.allocations.updatedTime,
    portfolio.relationships.stakeholder?.updatedTime,
    portfolio.relationships.supplier?.updatedTime,
  ]
    .filter((time) => time)
    .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
  return sortedUpdateTimes[0]
}
</script>
